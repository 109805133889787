<template>
  <!-- 背景 -->
  <img class="bg-gradient" src="../bootstrap/img/gradient2.png" alt="">

  <div class="bg-noise"></div>
  <div class="bgyanse"></div>
  <button class="zuopinjibtn" @click="goToPortfolio">作品集</button>
  <div>



    <!-- 文字滚动 -->
    <div class="marquee-text js-marquee-info border-bottom" id="marquee"> </div>
    <div class="container">
      <!-- 头图 -->
      <section>

        <div class="row d-flex align-items-center rowmargin-top">
          <p  class="dabiaoti dabiotidonghua">Z'WEI PORTFOLIO</p>
        </div>
        <div class="order">
          <div class="row d-flex align-items-center col-12 col-md-3  order2">

            <div style="align-items: center;justify-content: center; display: flex;"
              class=" item_2 col-xs-12 col-sm-12">
              <span class="juxingtext">2024</span>
              <swiper :slidesPerView="1" :spaceBetween="0" :loop="true" :freeMode="true" :autoplay="{
                delay: 1800,
                disableOnInteraction: false,
                reverseDirection: true,
              }" :modules="modules" class="juxing">
                <swiper-slide><img src="../bootstrap/img/paopao1.png" class="img-fluid " alt="..."></swiper-slide>
                <swiper-slide><img src="../bootstrap/img/paopao2.png" class="img-fluid " alt="..."></swiper-slide>
                <swiper-slide><img src="../bootstrap/img/paopao3.png" class="img-fluid " alt="..."></swiper-slide>
                <swiper-slide><img src="../bootstrap/img/paopao1.png" class="img-fluid " alt="..."></swiper-slide>
                <swiper-slide><img src="../bootstrap/img/paopao2.png" class="img-fluid " alt="..."></swiper-slide>
              </swiper>
            </div>

          </div>

          <div class="col-12 col-md-9 dabiaoti_1 order1">
            做有趣的事
          </div>
        </div>

   
        <div class="gsap-toutu__wrapper"><span class="qichengdonghua"><img class="img_2 position-absolute top-0 end-0 gsap-toutu__newstart bottom-effect "
          src="../../src/bootstrap/img/qicheng.png" alt="" /></span> </div>

        <div class="iphoneimg1  "> <img class="img_1 liwudonghua" src="../bootstrap/img/liwu.png" alt="" /></div>




        <div class="row toutu_figuer d-flex align-items-center divmargin-top bottom-effect">
          <img style="   border-radius: 50%;" class="figuer_img col-xxl-auto col-md-12 magnetic" src="../bootstrap/img/LOGO.png" alt="">
          <p class="figuer_text col-xxl-auto col-md-auto"> Zzw.</p>
        </div>



        <div class="row d-flex align-items-center touturow2 bottom-effect">
          <div class="col-lg-3 col-2"> </div>
          <div class="col-lg-auto col-10 ">
            <div class="toutu_row2text1">Designer & Developer</div>
            <div class="d-flex justify-content-between">
              <div class=" toutu_row2text2">灵感收集</div>

              <button style="border: 0;background-color: #00000000;" type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModal">
                <div class=" toutu_row2text2">Wechat</div>
              </button>
              <a href="https://dribbble.com/xiaowei00167" target="_blank">
                <div class=" toutu_row2text2">Dribbble</div>
              </a>
              <div @click="goToDocs" style="margin-right: 0;" class="toutu_row2text2">Blog</div>


            </div>
          </div>
        </div>



        <div class="row d-flex align-items-center row3juxingtop bottom-effect">
          <div class="col-lg-9 col-0 "> </div>
          <div class="col-lg-3 col-10 ">
            <div class="row">
              <div class="col-6  toutu_row3juxing magnetic">UI/UX</div>
              <div class="col-6  toutu_row3juxing magnetic">Web Development</div>
              <div class="col-6 toutu_row3juxing magnetic">BLENDER</div>
              <div class="col-6  toutu_row3juxing magnetic">PROTOTYPING</div>

            </div>
          </div>
        </div>

        <div class="row magnetic  bottom-effect ">
          <div class="col-md-auto row d-flex align-items-center justify-content-center">
            <img src="../bootstrap/img/wenzixuanzhuan.svg" alt="" class="toutu_row4wenzixuanzhuan " draggable="false">
            <!-- <img class="toutu_row4jiantou" src="../bootstrap/img/jiantou.png" alt=""> -->
            <svg class="toutu_row4jiantou" width="24" height="32" viewBox="0 2 24 32" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_913_531)">
                <path
                  d="M21.7314 18.2689L13.4877 26.5126V0.571411H10.9162V26.5126L2.67255 18.2689L0.857422 20.084L10.3868 29.6134L12.202 31.4286L14.0171 29.6134L23.5465 20.084L21.7314 18.2689Z"
                  fill="#222222" />
              </g>
              <defs>
                <clipPath id="clip0_913_531">
                  <rect width="20" height="30" fill="white" transform="translate(0.857422 0.571411)" />
                </clipPath>
              </defs>
            </svg>

          </div>
          <div class="col-8"> </div>
        </div>







      </section> <!-- 结束 -->

      <!-- banner -->
      <section>
        <div class="row rowmargin-top bannerpading bottom-effect ">

          <div @click="goToIcon" style="padding: 0;cursor: pointer;" class="card text-bg-dark cardyuanjiao bannercard1">
            <img src="../bootstrap/img/banner_1.png" class="card-img bannerhover" alt="...">
          </div>

          <!-- <div style="padding: 0;" class="card text-bg-dark cardyuanjiao divmargin-top bannercard2">
            <img src="../bootstrap/img/banner_2.png" class="card-img bannerhover" alt="...">
          </div> -->

        </div>



        <!-- <swiper
    :spaceBetween="0"
    :centeredSlides="true"
    :autoplay="{
      delay: 4000,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
    :navigation="true"
    :modules="modules"
    @autoplayTimeLeft="onAutoplayTimeLeft"
    class="mySwiper"
  >
    <swiper-slide @click="goToIcon"> <img src="../bootstrap/img/banner_1.png" class="card-img bannerhover" alt="..."></swiper-slide>
    <swiper-slide> <img src="../bootstrap/img/banner_2.png" class="card-img bannerhover" alt="..."></swiper-slide>
  </swiper> -->



      </section> <!-- 结束  -->




      <!-- 具有挑战性的在线项目 部分 -->
      <section>
        <div style="position: relative;" class=" row rowmargin-top  gsap-collections__title bottom-effect "> <!-- 标题 -->
          <h2 class="text_3">Design Practice Exercises</h2>
          <div>

            <DotLottieVue class="img_4 gsap-collections__sticker" autoplay loop
              src="https://lottie.host/c915f788-19e0-4f9d-9ae9-04e4960c9f9c/Un0YIbTp5e.json" style="width: 30%; " />
            <h3 class="text_4">具有挑战性的<br>在线项目.
              <!-- <img class="img_4 gsap-collections__sticker" src="../bootstrap/img/battery.png"> -->
            </h3>

          </div>
        </div>
        <!-- 标题结束 -->
       
        <!-- 按钮 -->
         <div style=""></div>
        <div style="display: flex; justify-content: flex-end;"  class=" bottom-effect ">
          <button style="margin-right: 10px" class="btn btn-outline-dark tiaozhanbtn_Prev" id="customPrevButton">←</button>
          <button class="btn btn-outline-dark tiaozhanbtn_Next" id="customNextButton">→</button>
        </div>



        <div class="row divmargin-top   bottom-effect ">
          <swiper ref="{swiperRef}"  :spaceBetween="20" :autoHeight="true"
            :pagination="{ type: 'custom' }" 
            :navigation="{
              hiddenClass: 'swiper-button-hidden',
              nextEl: '#customNextButton',
              prevEl: '#customPrevButton'
            }" 
            
            :loop="false"
            
            :breakpoints="{
              '640': {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              '768': {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              '769': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }" :modules="modules" class="tiaozhanmokuai">

            <swiper-slide style="background-color: transparent;">
              <div class="tiaozhancardbody">
                <div class="tiaozhanimg"> <img src="../bootstrap/img/car_1.png" alt="..."></div>
                <div class="tiaozhancardtext">
                  <p class="tiaozhanbigbiaoti">华夏基金管家</p>
                  <p class="tiaozhanzhengwen">华夏基金管家 APP 是华夏基金官方理财工具，集多种功能于一体，可买基金、查产品、进行多项操作，随时随地管理账户。</p>
                  <a @click="goToHuaxia" class="btn btn-outline-dark tiaozhanbtn">Go →</a>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide style="background-color: transparent;">
              <div class="tiaozhancardbody">
                <div class="tiaozhanimg"> <img src="../bootstrap/img/car_2.png" class="tiaozhanimg" alt="..."> </div>
                <div class="tiaozhancardtext">
                  <p class="tiaozhanbigbiaoti">医百顺</p>
                  <p class="tiaozhanzhengwen">医百顺小程序是便捷的医疗服务平台，提供在线问诊、预约挂号、健康资讯等服务，全心全意的服务，助力用户轻松管理健康。</p>
                  <a @click="goToYiBaiShun" class="btn btn-outline-dark tiaozhanbtn">Go →</a>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide style="background-color: transparent;">
              <div class="tiaozhancardbody">
                <div class="tiaozhanimg"> <img src="../bootstrap/img/car_3.png" class="tiaozhanimg" alt="..."> </div>
                <div class="tiaozhancardtext">
                  <p class="tiaozhanbigbiaoti">粉笔教育</p>
                  <p class="tiaozhanzhengwen">粉笔教育专注公职类考试辅导，在公务员和事业单位培训方面经验丰富，教师精准教研，解析命题规律，提供公考培训。</p>
                  <a @click="goToFenBi" class="btn btn-outline-dark tiaozhanbtn">Go →</a>
                </div>
              </div>
            </swiper-slide>


            <swiper-slide style="background-color: transparent;">
              <div class="tiaozhancardbody">
                <div class="tiaozhanimg"> <img src="../bootstrap/img/car_4.png" class="tiaozhanimg" alt="..."> </div>
                <div class="tiaozhancardtext">
                  <p class="tiaozhanbigbiaoti">鲨鱼记账</p>
                  <p class="tiaozhanzhengwen">鲨鱼记账是一款简洁易用的记账软件，功能强大。可轻松记录收支，分析消费趋势，助你更好地管理个人财务。</p>
                  <a @click="goToShaYu" class="btn btn-outline-dark tiaozhanbtn">Go →</a>
                </div>
              </div>
            </swiper-slide>



     


      

          </swiper>
        </div>



      </section>
      <!-- 结束 -->



      <!-- 视觉展示 部分 -->
      <section>
        <div style=" position: relative;" class="row gsap-recent__header  bottom-effect ">
          <p class="smtext col-12 text-center">VISUAL DISPLAY</p>
          <!-- <img class="webpng gsap-recent__sticker" src="../bootstrap/img/web.png" alt=""> -->
          <DotLottieVue class="webpng gsap-recent__sticker" autoplay loop
            src="https://lottie.host/4691ba39-afdf-45b2-a058-1ece9c2690e1/dbimHQVbhV.json" />
          <p class="bigtext col-12 text-center">视觉展示</p>
        </div>
        <!-- 标题结束 -->


        <div class="row divmargin-top   bottom-effect ">


          <swiper ref="{swiperRef}" :slidesPerView="1" :centeredSlides="false" :spaceBetween="20" :autoHeight="true"
            :pagination="{ type: 'custom' }" :breakpoints="{
              '640': {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              '768': {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              '769': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }" :modules="modules" class="shijuemokuai">



            <swiper-slide style="background-color: transparent;">
              <div style=" cursor: pointer;" @click="goToHaibaoZhongqiu" class="tiaozhancardbody ">
                <div class="shijuecard" style="background-color: #222222; border-radius: 10px;">
                  <div style="margin: 0;" class=" row">

                    <img class="shijueimg" src="img/zhongqiu/zhongqiu_sm.png" alt="">

                  </div>
                  <div class="row shijuedown">
                    <div class="col">
                      <span class="shijuesmtext">Mid-Autumn Festival。</span><br>
                      <span class="shijuebigtext">中秋海报</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">
                      <div class="rounded-box">
                        <span class="xuexizuopin">作品</span>
                        <span class="xuexishuliang">4个</span>
                      </div>
                    </div>

                  </div>

                  <div class="row shijuebutton">
                    <div class="col">
                      <span class="shijuewwwtext">www.mywebzzw.top</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">

                      <span class="shijuedata"><i class="bi bi-arrow-right"></i></span>


                    </div>


                  </div>

                </div>




              </div>
            </swiper-slide>

            <swiper-slide style="background-color: transparent;">
              <div style=" cursor: pointer;" @click="goToHaibaoZhongqiu" class="tiaozhancardbody ">
                <div class="shijuecard" style="background-color: #222222; border-radius: 10px;">
                  <div style="margin: 0;" class=" row">

                    <img class="shijueimg" src="img/zhongqiu/zhongqiu_sm.png" alt="">

                  </div>
                  <div class="row shijuedown">
                    <div class="col">
                      <span class="shijuesmtext">Mid-Autumn Festival。</span><br>
                      <span class="shijuebigtext">中秋海报</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">
                      <div class="rounded-box">
                        <span class="xuexizuopin">作品</span>
                        <span class="xuexishuliang">4个</span>
                      </div>
                    </div>

                  </div>

                  <div class="row shijuebutton">
                    <div class="col">
                      <span class="shijuewwwtext">www.mywebzzw.top</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">

                      <span class="shijuedata"><i class="bi bi-arrow-right"></i></span>


                    </div>


                  </div>

                </div>




              </div>
            </swiper-slide>

            <swiper-slide style="background-color: transparent;">
              <div style=" cursor: pointer;" @click="goToHaibaoZhongqiu" class="tiaozhancardbody ">
                <div class="shijuecard" style="background-color: #222222; border-radius: 10px;">
                  <div style="margin: 0;" class=" row">

                    <img class="shijueimg" src="img/zhongqiu/zhongqiu_sm.png" alt="">

                  </div>
                  <div class="row shijuedown">
                    <div class="col">
                      <span class="shijuesmtext">Mid-Autumn Festival。</span><br>
                      <span class="shijuebigtext">中秋海报</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">
                      <div class="rounded-box">
                        <span class="xuexizuopin">作品</span>
                        <span class="xuexishuliang">4个</span>
                      </div>
                    </div>

                  </div>

                  <div class="row shijuebutton">
                    <div class="col">
                      <span class="shijuewwwtext">www.mywebzzw.top</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">

                      <span class="shijuedata"><i class="bi bi-arrow-right"></i></span>


                    </div>


                  </div>

                </div>




              </div>
            </swiper-slide>

            <swiper-slide style="background-color: transparent;">
              <div style=" cursor: pointer;" @click="goToHaibaoZhongqiu" class="tiaozhancardbody ">
                <div class="shijuecard" style="background-color: #222222; border-radius: 10px;">
                  <div style="margin: 0;" class=" row">

                    <img class="shijueimg" src="img/zhongqiu/zhongqiu_sm.png" alt="">

                  </div>
                  <div class="row shijuedown">
                    <div class="col">
                      <span class="shijuesmtext">Mid-Autumn Festival。</span><br>
                      <span class="shijuebigtext">中秋海报</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">
                      <div class="rounded-box">
                        <span class="xuexizuopin">作品</span>
                        <span class="xuexishuliang">4个</span>
                      </div>
                    </div>

                  </div>

                  <div class="row shijuebutton">
                    <div class="col">
                      <span class="shijuewwwtext">www.mywebzzw.top</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">

                      <span class="shijuedata"><i class="bi bi-arrow-right"></i></span>


                    </div>


                  </div>

                </div>




              </div>
            </swiper-slide>

            <swiper-slide style="background-color: transparent;">
              <div style=" cursor: pointer;" @click="goToHaibaoZhongqiu" class="tiaozhancardbody ">
                <div class="shijuecard" style="background-color: #222222; border-radius: 10px;">
                  <div style="margin: 0;" class=" row">

                    <img class="shijueimg" src="img/zhongqiu/zhongqiu_sm.png" alt="">

                  </div>
                  <div class="row shijuedown">
                    <div class="col">
                      <span class="shijuesmtext">Mid-Autumn Festival。</span><br>
                      <span class="shijuebigtext">中秋海报</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">
                      <div class="rounded-box">
                        <span class="xuexizuopin">作品</span>
                        <span class="xuexishuliang">4个</span>
                      </div>
                    </div>

                  </div>

                  <div class="row shijuebutton">
                    <div class="col">
                      <span class="shijuewwwtext">www.mywebzzw.top</span>
                    </div>

                    <div class="col d-flex justify-content-end align-self-center">

                      <span class="shijuedata"><i class="bi bi-arrow-right"></i></span>


                    </div>


                  </div>

                </div>




              </div>
            </swiper-slide>


          </swiper>







        </div>








      </section>
      <!-- 结束 -->

      <!-- 上线网站 -->
      <section>
        <div class="rowmargin-top  bottom-effect">

          <div style="position: relative;" class="row gsap-www__header">
            <p class="smtext col-12 text-center">ONLINE WEBSITES</p>
            <img class="wwwpng gsap-www__sticker" src="../bootstrap/img/web.png" alt="">
            <p class="bigtext col-12 text-center">网站设计</p>
          </div>
          <!-- 卡开始 -->
          <div style="margin: 0;" class="row divmargin-top">
            <!-- 开始 -->
            <div @click="goToHome" class="col-lg-6 col-md-12 col-xs-12 cardbg divmargin-top"> <!-- 卡开始 -->
              <div class="card mb-3 shangxianwnagzhancard">
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="../bootstrap/img/webimg_1.png" class="img-fluid" alt="...">
                  </div>
                  <div class="col-lg-8 col-md-8">
                    <div class="card-body text-zuoduiqi">
                      <div class="d-flex justify-content-between row shangxianwangzhanbiaoti">
                        <p class="card-text"><small class="text-muted">PERSONAL WEBSITE WEBSITE</small></p>

                      </div>
                      <h5 class="card-title">个人网站</h5>


                      <p class="card-text">
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">原型</span>
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">UI</span>
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">开发</span>
                      </p>
                      <p class="card-textday"><small class="text-muted  ">上线日期：2024.7.25</small></p>
                    </div>
                    <div class="position-absolute top-0 end-0 shangxianwangzhan-btn_1"><img
                        class="shangxianwangzhan-img_1" src="../bootstrap/img/jiantou.png" alt=""></div>
                  </div>
                </div>

              </div>
            </div> <!-- 卡结束 -->
            <!-- 开始 -->
            <div @click="goToDocs" class="col-lg-6 col-md-12 col-xs-12 cardbg divmargin-top"> <!-- 卡开始 -->
              <div class="card mb-3 shangxianwnagzhancard">
                <div class="row g-0">
                  <div class="col-md-4 ">
                    <img src="../bootstrap/img/webimg_2.png" class="img-fluid" alt="...">
                  </div>
                  <div class="col-lg-8 col-md-8">
                    <div class="card-body text-zuoduiqi">
                      <div class="d-flex justify-content-between row shangxianwangzhanbiaoti">
                        <p class="card-text"><small class="text-muted  ">PERSONAL WEBSITE WEBSITE</small></p>

                      </div>
                      <h5 class="card-title">个人博客</h5>


                      <p class="card-text">
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">原型</span>
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">UI</span>
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">开发</span>
                      </p>
                      <p class="card-textday"><small class="text-muted">上线日期：2024.9.26</small></p>
                    </div>
                    <div class="position-absolute top-0 end-0 shangxianwangzhan-btn_1"><img
                        class="shangxianwangzhan-img_1" src="../bootstrap/img/jiantou.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div> <!-- 卡结束 -->
     <!-- 开始 -->
     <div @click="goTSSaaSWeb" class="col-lg-6 col-md-12 col-xs-12 cardbg divmargin-top"> <!-- 卡开始 -->
              <div class="card mb-3 shangxianwnagzhancard">
                <div class="row g-0">
                  <div class="col-md-4 ">
                    <img src="../bootstrap/img/webimg_3.png" class="img-fluid" alt="...">
                  </div>
                  <div class="col-lg-8 col-md-8">
                    <div class="card-body text-zuoduiqi">
                      <div class="d-flex justify-content-between row shangxianwangzhanbiaoti">
                        <p class="card-text"><small class="text-muted  ">Saas WebsiteKit</small></p>

                      </div>
                      <h5 class="card-title">SaaS 套件网站</h5>
                      <p class="card-text">
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">WEB</span>
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">3D</span>
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">海外</span>
                      </p>
                      <p class="card-textday"><small class="text-muted">设计日期：2021.10.28</small></p>
                    </div>
                    <div class="position-absolute top-0 end-0 shangxianwangzhan-btn_1"><img
                        class="shangxianwangzhan-img_1" src="../bootstrap/img/jiantou.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div> <!-- 卡结束 -->   
             <!-- 开始 -->
            <div @click="goToHuXiaWEB" class="col-lg-6 col-md-12 col-xs-12 cardbg divmargin-top"> <!-- 卡开始 -->
              <div class="card mb-3 shangxianwnagzhancard">
                <div class="row g-0">
                  <div class="col-md-4 ">
                    <img src="../bootstrap/img/webimg_4.png" class="img-fluid" alt="...">
                  </div>
                  <div class="col-lg-8 col-md-8">
                    <div class="card-body text-zuoduiqi">
                      <div class="d-flex justify-content-between row shangxianwangzhanbiaoti">
                        <p class="card-text"><small class="text-muted  ">CHINAAMC OFFICIAL WEBSITE</small></p>

                      </div>
                      <h5 class="card-title">华夏基金官方网站</h5>


                      <p class="card-text">
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">金融</span>
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">网页</span>
                        <span style="padding: 5px 10px 5px 10px;margin-right: 8px;" class="badge text-bg-dark">门户</span>
                      </p>
                      <p class="card-textday"><small class="text-muted">上线日期：2000.5.19</small></p>
                    </div>
                    <div class="position-absolute top-0 end-0 shangxianwangzhan-btn_1"><img
                        class="shangxianwangzhan-img_1" src="../bootstrap/img/jiantou.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div> <!-- 卡结束 -->


          </div>

        </div>





      </section><!-- 结束  -->


      <!-- 资产展示 -->
      <section>
        <div class="row zichanbody rowmargin-top bottom-effect ">
          <div class="zichanmarquee-text js-marquee-info " id="new-marquee-container"> </div>
          <span
            class="zichaniphonetext row d-flex justify-content-center align-items-center">资产展示·ICON·WEB·APP·3D</span>
          <swiper :slidesPerView="2" :spaceBetween="15" :loop="true" :freeMode="true" :autoplay="{
            delay: 1800,
            disableOnInteraction: false,
          }" :breakpoints="{
            '768': {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            '769': {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }" :modules="modules" class="zichanSwiper">
            <swiper-slide><img src="../bootstrap/img/zichan_1.png" class="img-fluid " alt="..."></swiper-slide>
            <swiper-slide><img src="../bootstrap/img/zichan_2.png" class="img-fluid " alt="..."></swiper-slide>
            <swiper-slide><img src="../bootstrap/img/zichan_1.png" class="img-fluid " alt="..."></swiper-slide>
            <swiper-slide><img src="../bootstrap/img/zichan_1.png" class="img-fluid " alt="..."></swiper-slide>
            <swiper-slide><img src="../bootstrap/img/zichan_2.png" class="img-fluid " alt="..."></swiper-slide>
          </swiper>
          <swiper :slidesPerView="3" :spaceBetween="15" :loop="true" :freeMode="true" :autoplay="{
            delay: 1800,
            disableOnInteraction: false,
            reverseDirection: true,

          }" :breakpoints="{
            '768': {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            '769': {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }" :modules="modules" class="zichanSwiper">
            <swiper-slide><img src="../bootstrap/img/zichan_3.png" class="img-fluid " alt="..."></swiper-slide>
            <swiper-slide><img src="../bootstrap/img/zichan_1.png" class="img-fluid " alt="..."></swiper-slide>
            <swiper-slide><img src="../bootstrap/img/zichan_2.png" class="img-fluid " alt="..."></swiper-slide>
            <swiper-slide><img src="../bootstrap/img/zichan_3.png" class="img-fluid " alt="..."></swiper-slide>
            <swiper-slide><img src="../bootstrap/img/zichan_1.png" class="img-fluid " alt="..."></swiper-slide>
          </swiper>

        </div>


      </section> <!-- 结束 -->

      <!-- 设计之外 -->
      <section>
        <div class="rowmargin-top">

          <!-- 标题 -->
          <div style=" position: relative;" class="row gsap-ufo__header   bottom-effect ">
            <p class="smtext col-12 text-center">EXPLORE</p>
            <img class="shejizhiwaipng gsap-ufo__sticker" src="../bootstrap/img/UFO.png" alt="">
            <p class="bigtext col-12 text-center">设计之外</p>
          </div>
          <!-- 标题结束 -->

          <div class="row row-cols-1 row-cols-md-2 g-4 divmargin-top  bottom-effect ">
            <div class="col">
              <div style="border-radius:20px;background-color: #222529;" class="card">
                <div class="shejizhiwaicard">
                  <div class="shejizhiwaismtext row">Never stop learning.</div>
                  <h5 class="shejizhiwaibigtext row">学无止境</h5>
                  <div class="row">
                    <button type="button" class="btn btn-outline-dark btn_1" @click="goToDocs">进入博客</button>
                  </div>
                  <img class="img_6" src="../bootstrap/img/zuanshi.png">
                </div>
              </div>
            </div>
            <div class="col">
              <div style="border-radius:20px;background-color: #222529;" class="card">
                <div class="shejizhiwaicard">
                  <div class="shejizhiwaismtext row">Web Design Inspiration..</div>
                  <h5 class="shejizhiwaibigtext row">个人网站</h5>
                  <div class="row">
                    <button type="button" class="btn btn-outline-dark btn_1">mywebzzw.top</button>
                  </div>
                  <img class="img_6" src="../bootstrap/img/jianpan.png">
                </div>
              </div>
            </div>
          </div>
        </div>









      </section> <!-- 结束 -->

      <!-- 学习探索 -->
      <section>

        <div style="position: relative;" class=" row rowmargin-top  gsap-xuexi__title  right-effect "> <!-- 标题 -->
          <h2 class="text_3">Design Practice Exercises</h2>
          <div>

            <DotLottieVue class="img_5 gsap-xuexi__sticker" autoplay loop
              src="https://lottie.host/3cfe671d-492f-4360-b999-39f76d450e22/vzkoX17OVO.json" style="width: 50%; " />
            <h3 class="text_4">学习探索<br>及杂谈.
              <!-- <img class="img_4 gsap-collections__sticker" src="../bootstrap/img/battery.png"> -->
            </h3>
          </div>
        </div>



        <!-- 标题结束 -->

        <!-- 行开始 -->
         <div class="right-effect">
        <div class="row divmargin-top magnetic d-flex justify-content-between align-items-center xuexizuomokuai ">
          <div style="padding: 0;" class="col-10 align-items-center d-flex">
            <span class="xuexibiaoqian">AICG</span>
            <span class="xuexibiaoti">AI绘图趋势学习</span>
          </div>
          <svg class="col-auto xuexijiantou" width="24" ghthei="32" viewBox="0 2 24 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_913_531)">
              <path
                d="M21.7314 18.2689L13.4877 26.5126V0.571411H10.9162V26.5126L2.67255 18.2689L0.857422 20.084L10.3868 29.6134L12.202 31.4286L14.0171 29.6134L23.5465 20.084L21.7314 18.2689Z"
                fill="#222222" />
            </g>
            <defs>
              <clipPath id="clip0_913_531">
                <rect width="20" height="30" fill="white" transform="translate(0.857422 0.571411)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        </div>
        <!-- 行结束 -->
        <!-- 行开始 -->
        <div class="right-effect">
        <div class="row magnetic d-flex justify-content-between align-items-center xuexizuomokuai ">
          <div style="padding: 0;" class="col-10 align-items-center d-flex">
            <span class="xuexibiaoqian">JavaScript</span>
            <span class="xuexibiaoti">前段学习记录</span>
          </div>
          <svg class="col-auto xuexijiantou" width="24" height="32" viewBox="0 2 24 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_913_531)">
              <path
                d="M21.7314 18.2689L13.4877 26.5126V0.571411H10.9162V26.5126L2.67255 18.2689L0.857422 20.084L10.3868 29.6134L12.202 31.4286L14.0171 29.6134L23.5465 20.084L21.7314 18.2689Z"
                fill="#222222" />
            </g>
            <defs>
              <clipPath id="clip0_913_531">
                <rect width="20" height="30" fill="white" transform="translate(0.857422 0.571411)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
        <!-- 行结束 -->
        <!-- 行开始 -->
        <div class="right-effect">
        <div class="row magnetic d-flex justify-content-between align-items-center xuexizuomokuai">
          <div style="padding: 0;" class="col-10 align-items-center d-flex">
            <span class="xuexibiaoqian">Blog</span>
            <span class="xuexibiaoti">工作与学习</span>
          </div>
          <svg class="col-auto xuexijiantou" width="24" height="32" viewBox="0 2 24 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_913_531)">
              <path
                d="M21.7314 18.2689L13.4877 26.5126V0.571411H10.9162V26.5126L2.67255 18.2689L0.857422 20.084L10.3868 29.6134L12.202 31.4286L14.0171 29.6134L23.5465 20.084L21.7314 18.2689Z"
                fill="#222222" />
            </g>
            <defs>
              <clipPath id="clip0_913_531">
                <rect width="20" height="30" fill="white" transform="translate(0.857422 0.571411)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
        <!-- 行结束 -->
        <!-- 行开始 -->
        <div class="right-effect">
        <div class="row magnetic d-flex justify-content-between align-items-center xuexizuomokuai">
          <div style="padding: 0;" class="col-10  align-items-center d-flex">
            <span class="xuexibiaoqian">3D</span>
            <span class="xuexibiaoti">3D元素学习探索</span>
          </div>
          <svg class="col-auto xuexijiantou" width="24" height="32" viewBox="0 2 24 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_913_531)">
              <path
                d="M21.7314 18.2689L13.4877 26.5126V0.571411H10.9162V26.5126L2.67255 18.2689L0.857422 20.084L10.3868 29.6134L12.202 31.4286L14.0171 29.6134L23.5465 20.084L21.7314 18.2689Z"
                fill="#222222" />
            </g>
            <defs>
              <clipPath id="clip0_913_531">
                <rect width="20" height="30" fill="white" transform="translate(0.857422 0.571411)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
        <!-- 行结束 -->


        <!-- 行开始 -->
        <div class="right-effect">
        <div class="row magnetic d-flex justify-content-between align-items-center xuexizuomokuai">
          <div style="padding: 0;" class="col-10 align-items-center d-flex">
            <span class="xuexibiaoqian ">WEB</span>
            <span class="xuexibiaoti">前段学习记录</span>
          </div>
          <svg class="col-auto xuexijiantou" width="24" height="32" viewBox="0 2 24 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_913_531)">
              <path
                d="M21.7314 18.2689L13.4877 26.5126V0.571411H10.9162V26.5126L2.67255 18.2689L0.857422 20.084L10.3868 29.6134L12.202 31.4286L14.0171 29.6134L23.5465 20.084L21.7314 18.2689Z"
                fill="#222222" />
            </g>
            <defs>
              <clipPath id="clip0_913_531">
                <rect width="20" height="30" fill="white" transform="translate(0.857422 0.571411)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
        <!-- 行结束 -->
      </section> <!-- 结束 -->

    </div>
    <!-- 网页内容结束 -->
    <!-- 版权 -->

  </div>
</template>


<style scoped>

.toutu5donghua{
  opacity:0;
  animation: fadeInUp;
     animation-duration: 1s;
     animation-delay: 3.4s; /* 动画延迟1秒开始 */
     animation-iteration-count: 1; /* 动画重复 */
     animation-fill-mode:forwards;
}
.toutu4donghua{
  opacity:0;
  animation: fadeInUp;
     animation-duration: 1s;
     animation-delay: 3.1s; /* 动画延迟1秒开始 */
     animation-iteration-count: 1; /* 动画重复 */
     animation-fill-mode:forwards;
}
.toutu3donghua{
  opacity:0;
  animation: fadeInUp;
     animation-duration: 1s;
     animation-delay: 2.8s; /* 动画延迟1秒开始 */
     animation-iteration-count: 1; /* 动画重复 */
     animation-fill-mode:forwards;
}
.toutu2donghua{
  opacity:0;
  animation: fadeInUp;
     animation-duration: 1s;
     animation-delay: 2.4s; /* 动画延迟1秒开始 */
     animation-iteration-count: 1; /* 动画重复 */
     animation-fill-mode:forwards;
}
.qichengdonghua{
  opacity:0;
  animation: fadeInUp;
     animation-duration: 1s;
     animation-delay: 2.1s; /* 动画延迟1秒开始 */
     animation-iteration-count: 1; /* 动画重复 */
     animation-fill-mode:forwards;
}
.liwudonghua{
  opacity:0;
  animation: fadeInUp;
     animation-duration: 1s;
     animation-delay: 1.9s; /* 动画延迟1秒开始 */
     animation-iteration-count: 1; /* 动画重复 */
     animation-fill-mode:forwards;
}
.dabiotidonghua {
  display: inline-block;
  opacity:0;
  animation: fadeInUp;
     animation-duration: 1s;
     animation-delay: 1.5s; /* 动画延迟1秒开始 */
     animation-iteration-count: 1; /* 动画重复 */
     animation-fill-mode:forwards;
}

.btn_1 {
  z-index: 9999;
}

.swiper {
  height: auto;
}

a {
  text-decoration: none;
}

/* 头图 */
.toutu_row3juxing {
  font-family: "Source Han Sans CN", sans-serif;
  /* 设置字体为思源黑体，如果需要可以指定不同的字重 */
  font-weight: 600;

}

.toutu_row2text1 {
  font-family: "Source Han Sans CN", sans-serif;
  /* 设置字体为思源黑体，如果需要可以指定不同的字重 */
  font-weight: 600;
  margin-left: 10px;

}

.toutu_row2text2 {
  font-family: "Source Han Sans CN", sans-serif;
  /* 设置字体为思源黑体，如果需要可以指定不同的字重 */
  font-weight: 400;
  cursor: pointer;
}

.dabiaoti {
  white-space: nowrap;
  text-align: center;
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 5px;

}

.dabiaoti_1 {
  text-align: left;

  font-family: "PingFang", Arial, sans-serif;
  font-weight: 900;
}

.figuer_text {

  font-family: "PingFang", Arial, sans-serif;
  font-weight: 400;
  color: #000;
}

.toutu_row2text1 {
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 500;
}

.order {
  display: flex;
  opacity:0;
  animation: fadeInUp;
     animation-duration: 1s;
     animation-delay: 3s; /* 动画延迟1秒开始 */
     animation-iteration-count: 1; /* 动画重复 */
     animation-fill-mode:forwards;
}

/* 头图结束 */

/* banner */
.bannerhover {
  transition: transform 1.5s ease, box-shadow 1.5s ease;
  border: 0;
}

.bannerhover:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.04);
  border: 0;
}

.cardyuanjiao {
  border-radius: 20px;
}

.card-img {
  height: 100%;
  /* 让图片高度填满父容器 */
  object-fit: cover;
  /* 裁剪图片以填充容器 */
  width: 100%;
  box-shadow: 0 1px 10px rgba(224, 224, 224, 0.5);
  /* 为 SVG 图片增加阴影 */
  border-radius: 15px;
}


.card {
  border: none;
  /* 消除卡片的边框 */
}

/* banner结束 */

/* 挑战模块 */
.tiaozhanimg {
  position: relative;
  display: block;
}

.tiaozhanimg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.549));
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
}

.tiaozhanimg:hover::after {
  opacity: 1;
}


.tiaozhanzhengwen {
  color: #666666;
  font-weight: 300;
}



.tiaozhanbtn_Next {
  width: 50px;
  height: 50px;
  font-size: 20px;
  border-radius: 50%;
  /* 或者 50% */
}

.tiaozhanbtn_Prev {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* 或者 50% */
  font-size: 20px;
}

.swiper-button-next {
  color: #000000;
}

.swiper-button-prev {
  color: #000000;
}

/* 上线网站 */
.shangxianwnagzhancard {
  background-color: transparent;
}

.swiper-button-hidden {
  display: none !important;
}

/* 学习杂谈模块 */
.xuexibiaoqian {
  font-weight: 400;
  font-family: "Source Han Sans CN", sans-serif;
}

.xuexibiaoti {
  font-weight: 500;
  font-family: "Source Han Sans CN", sans-serif;
}

/* 背景 */
.bg-noise {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-image: url(../bootstrap/img/noise.gif);
  background-position: 0 0, 0 0;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  opacity: .05;
}

.bg-gradient {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 130vh;
  background-image: url(../bootstrap/img/gradient2.jpg);
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;

}

.bg-gradient::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
  height: 15vh;
  background: linear-gradient(180deg, rgba(236, 239, 248, 0) 11.11%, #EDEFF8 100%);
}

.bgyanse {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f8ff;
  z-index: -3;
  /* 确保在.bg-noise 下面 */
}


/* 按钮样式 */
.btn-outline-dark.custom-modified {
  border-color: white;
  color: white;
}

.btn-outline-dark.custom-modified:hover,
.btn-outline-dark.custom-modified:focus,
.btn-outline-dark.custom-modified:active {
  color: black;
  background-color: white;
}

/* 设计之外 */

.custom-gap {
  width: 20px;
  /* 调整间隙大小 */
}


.banner {
  padding: 1rem;
  width: 48%;
  text-align: left;
  height: 24rem;
  border-radius: 1.2rem;
}

.shejiwaitext1 {
  font-size: 0.8rem;
  color: #cacaca;
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 100;
}

.shejiwaitext2 {
  font-size: 3rem;
  color: #ffffff;
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 800;
}

.hint {
  padding-left: 22rem;
  font-size: 0.8rem;
  color: #ffffff82;
}

.button_1 {
  padding-top: 8rem;
}



/* 手机端 */

@media only screen and (max-width: 768px) {

  /* 作品集按钮 */
.zuopinjibtn   {
    position: fixed;
    bottom: 80px;
    right: 40px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 30px;
    width: 38px;
    height: 100px;
    font-size: 20px;
    cursor: pointer;
    z-index: 9999;
    text-align: center;
    writing-mode: vertical-lr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 0;
    line-height: 1;
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    animation-duration: 1s;
  }

  .zuopinjibtn:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.305);
    /* 鼠标悬停时添加投影 */
    transform: scale(1.2);
    /* 鼠标悬停时放大按钮 */
  }

  /* 网页一级标题样式 */
  .img_4 {
    position: absolute;
    top: 17vw;
    left: 32vw;
    transform: scale(1.6);
  }

  .img_5 {
    position: absolute;
    top: 12vw;
    left: 10vw;

    /* 设置浮动属性，如向左浮动 */

  }

  .text_3 {
    font-size: 3vw;
    text-align: start;
    padding-bottom: 1rem;
    color: #999999;

  }

  .text_4 {
    font-size: 8vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    padding: 0;
    text-align: start;
    height: auto;

  }

  /* 网页一级标题样式结束 */
  /* 头图走马灯 */
  .marquee-text {
    font-size: 0.1vw;
    background-color: transparent;
    font-weight: 500;
    padding: 0px;
    letter-spacing: 5px;
  }

  .rowmargin-top {
    margin-top: 2rem;

  }

  .divmargin-top {
    margin-top: 1rem;
  }

  .swiper-slide {
    border-radius: 10px;
  }

  .order {
    display: flex;
    flex-wrap: wrap;
  }

  .order1 {
    order: 1;
    width: 100%;
    justify-content: center;
    margin: 0;
  }

  .order2 {
    order: 2;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .container {
    margin: 0;
    max-width: 100%;
    padding: 0 20px 0 20px;
  }

  /* 头图 */
  .touturow2 {
    margin: 0;
  }

  .toutu_row4jiantou {
    display: none;
  }

  .toutu_row4wenzixuanzhuan {
    display: none;
  }

  .row3juxingtop {

    margin: 5vw 0 0 0;
  }

  .toutu_row3juxing {
    width: auto;
    height: 3vw;
    border: 1px solid rgb(0, 0, 0);
    padding: 4vw 5vw 4vw 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vw;
    font-size: 3vw;
    margin: 5px;
  }

  .toutu_row2text1 {
    font-size: 4vw;
    text-align: end;
    margin-top: 5vw;
  }

  .toutu_row2text2 {
    font-size: 3vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    margin: 10px 5px 40px 0px;
  }

  .toutu_row2text2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .toutu_row2text2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  .toutu_figuer {
    padding-left: 20px;
    margin: 5vw 20px 0 20px;
    padding: 0;
  }

  .figuer_text {
    padding: 0;
    margin: 0;
    font-size: 8vw;
    width: auto;
    text-align: center;
  }

  .figuer_img {
    width: 18vw;
  }

  .juxingtext {
    color: #FFFFFF;
    font-size: 10vw;
    position: absolute;
    z-index: 2;
    text-align: center;
  }

  .dabiaoti {
    margin-top: 20px;
    font-size: 8vw;
    white-space: normal;
    line-height: 1;
    order: 1;
    letter-spacing: 0;
  }

  .dabiaoti_1 {
    font-size: 16vw;
    white-space: normal;
    text-align: center;
    line-height: 0.8;
    margin-bottom: 40px;
  }

  .iphoneimg1 {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .img_1 {
    width: 80%;
  }

  .img_2 {
    margin-top: 30%;
    margin-right: 38%;
    width: 24vw;
    overflow: hidden
  }

  .juxing {
    width: 280px;
    height: 130px;
    background-color: #836aff;
    border-radius: 100px;
  }

  /* 头图结束 */

  /* banner */
  .bannerpading {
    margin: 2rem 0 0 0;
  }

  .bannercard1 {
    height: 50vw;
  }

  .bannercard2 {
    height: 30vw;
  }

  .img-fluid {
    border-radius: 10px;
  }

  /* banner结束 */

  /* 具有挑战性模块 */
  .tiaozhanzhengwen {
    font-size: 3.5vw;
    text-align: justify;
    color: #666666;
  }

  .tiaozhanbigbiaoti {
    font-size: 6vw;
    text-align: start;
    margin: 0;
    line-height: 2;
  }

  .tiaozhanbtn_Next {
    transform: scale(0.8)
  }

  .tiaozhanbtn_Prev {
    transform: scale(0.8)
  }

  .tiaozhanbtn {
    width: 20vw;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

  }



  .tiaozhancardbody {
    text-align: start;
    background-color: transparent;
    text-align: start;
  }

  /* 具有挑战性结束 */

  /* 视觉模块开始 */
  .xuexizuopin {
    font-weight: 300;
    font-size: 2.6vw;
    color: #ffffff90;
  }

  .xuexishuliang {
    font-weight: 600;
    font-size: 5vw;
    color: #ffffff;
  }

  .rounded-box {
    width: 15vw;
    height: 15vw;
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    /* 这里设置了半透明的黑色背景，可根据需求调整颜色和透明度 */
    color: #FFFFFF;
    margin: 0;
    padding: 0;
  }

  .shijueimg {

    object-fit: contain;
    margin: 0;
    padding: 0;
  }

  .shijueicon {
    transform: scale(2.5);
  }

  .shijuecard {
    padding: 8vw;
    height: auto;
  }



  .shijuesmtext {
    color: #FFFFFF;
    font-size: 2vw;
    font-weight: 200;
  }

  .shijuebigtext {
    color: #FFFFFF;
    font-size: 8vw;
    font-weight: 700;
  }

  .shijuewwwtext {
    color: #FFFFFF;
    font-size: 1.8vw;
    font-weight: 200;
    margin: 0vw 0 3vw 0vw;
  }

  .shijuedata {
    color: #FFFFFF;
    font-size: 1.8vw;
    font-weight: 200;
    margin: 0vw 0 0vw 1vw;
  }

  .shijuebutton {
    margin: 2vw 0 2vw 0;
  }

  .shijuedown {
    margin: 2vw 0 3vw 0;
    padding: 0;
  }

  /* 视觉模块结束 */



  /* 上线网站模块 */
  .webpng {
display: none;
  }

  .wwwpng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    bottom: 1.5em;
    left: 0;
    width: clamp(100px, 20vw, 200px);
  }

  .shejizhiwaipng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 1em;
    width: clamp(100px, 35vw, 300px);
  }

  .shangxianwangzhan-btn_1 {
    margin-top: 25rem;
    margin-right: 50px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 2px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
  }

  .shangxianwangzhan-btn_1:hover {
    background-color: #000000;
    transform: rotate(45deg);
  }

  .shangxianwangzhan-btn_1:hover img {
    filter: brightness(100) invert(1);
  }

  .shangxianwangzhan-img_1 {
    height: 20px;
    width: 20px;
    box-shadow: none !important;
    margin-top: 0 !important;
  }

  .text-zuoduiqi {
    padding: 20px 20px 0px 20px;
  }

  .cardbg {
    background-color: #FFFFFF;
    padding-top: 20px;
    border-radius: 20px;
  }

  .shangxianwnagzhancard {
    border-radius: 16px;
    padding: 0;

  }

  .shangxianwnagzhancard img {

    border-radius: 10px;
  }

  .bigtext {
    margin: 0 auto;
    text-align: center;
    font-size: 5rem;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 800;
  }

  .smtext {
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }

  .shangxianwangzhanbiaoti {
    height: 40px;
  }

  .shangxianwangzhanbiaoti svg {
    display: none;
  }

  /* 上线网站结束 */
  /* 资产模块 */
  .zichanmarquee-text {
    font-family: "Source Han Sans CN", sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 4vw;
    background-color: transparent;
    font-weight: 400;
    padding: 40px 20px 40px 20px;
    letter-spacing: 2px;
  }

  .zichanSwiper {
    height: 200px;
  }

  .zichanmarquee-text {
    font-family: "Source Han Sans CN", sans-serif;
    font-weight: bold;
    color: #ffffff00;
    font-size: 1px;
    background-color: transparent;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }

  .zichaniphonetext {
    font-size: 5vw;
    color: #ffffff;
    padding: 20px 0 20px 0;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 700;
    text-align: center;
  }

  .zichanbody {
    background-color: #151B29;
    padding: 5px 5px 5px 5px;
    border-radius: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  /* 资产结束 */
  /* 设计之外css */
  .img_6 {
    float: left;
    width: 90%;
    transform: translate3d(50%, -50%, 0px);
    transition: transform 4s ease, opacity 4s ease;
    opacity: 0.4;

  }

  .shejizhiwaicard:hover>.img_6 {
    transform: scale(1.1) translate3d(25%, -50%, 0px);
    opacity: 0.5;
    float: left;
  }

  .shejizhiwaibigtext {
    margin: 0 auto;
    color: #ffffff;
    text-align: start;
    font-size: 2rem;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 500;
    margin-top: 2rem;
  }

  .shejizhiwaismtext {
    margin: 0 auto;
    font-size: 1rem;
    color: #ffffffb5;
    margin-top: 2.8rem;
    text-align: start;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 500;
  }

  .shejizhiwaicard {
    padding: 2rem 0rem 2rem 2rem;
    text-align: start;
    overflow: hidden;
    height: 300px;
    width: 100%;
  }

  .btn_1 {

    border: 0.8px solid white;
    color: #ffffff;
    padding: 0.8rem 2rem 0.8rem 2rem;
    text-align: start;
    width: auto;
    margin-top: 1rem;
  }

  .btn_1:hover {
    background-color: #FFFFFF;
    color: black;
    text-align: start;
    width: auto;
    margin-top: 1rem;
  }

  .btn_2:hover {
    transform: scale(1.1) translate3d(-1%, -1%, 0px);

  }

  /* 设计之外结束 */

  /* 学习探索css */
  .xuexizuomokuai {
    border-bottom: 1px solid #333333;
    height: 80px;
    margin: 0;
    padding: 0;
  }

  .xuexibiaoqian {
    width: auto;
    padding: 0.6vw 3vw 0.6vw 3vw;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 0.75px solid black;
  }

  .xuexibiaoti {
    font-size: 5vw;
    padding-left: 2vw;
  }

  .xuexizuomokuai:hover .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;
    transition: transform 1s ease;
    transform: rotate(-90deg);
  }

  .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;
    transform: rotate(-135deg);
    transition: transform 1s ease;
  }

  .hangtop {
    margin-top: 0.4vw;
  }

  .biaoqian-xuexi {
    border: 0.7px solid #999999;
    padding: 0.6rem 1.5rem 0.6rem 1.5rem;
    margin-right: 0.5rem;
  }

  .btn_2 {
    font-size: 2rem;
    height: 100px;
    color: #333333;
    transition: transform 1s ease,

  }

  .btn_2:hover {
    transform: scale(1.1) translate3d(-1%, -1%, 0px);
  }

  .qianduanxuexi_biaoti {
    font-kerning: 2rem;
  }

  .xuexitansuospanyou {
    all: initial;
    text-align: end;
    padding-left: 60%;
  }

  /* 学习探索结束 */
}

/* pad端 */

@media only screen and (min-width: 769px) and (max-width: 1025px) {

  /* 作品集按钮 */
  .zuopinjibtn {
   display: none;
  }



  /* 走马灯 */
  .marquee-text {
    font-size: 4vw;
    background-color: transparent;
    font-weight: 500;
    padding: 10px;
    letter-spacing: 5px;
  }

  .swiper-slide {
    border-radius: 10px;
  }

  .mySwiper {
    height: 400px;
  }

  .img-fluid {
    border-radius: 10px;
  }

  /* 头图 */
  .toutu_row4jiantou {
    position: absolute;
    margin-top: 8vw;
  }

  .toutu_row4wenzixuanzhuan {
    width: 100%;
    height: 100%;
    animation: rotateAnimation 10s linear infinite;
    margin-top: 4vw;
  }

  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .row3juxingtop {
    margin-top: 4vw;
  }

  .toutu_row3juxing {
    width: auto;
    height: 3vw;
    border: 1px solid rgb(0, 0, 0);
    padding: 3vw 4vw 3vw 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vw;
    font-size: 2vw;
    margin: 5px;

  }

  .toutu_row2text1 {
    font-size: 3vw;
    margin: 3vw 0px 0px 0px;
    text-align: end;
  }

  .toutu_row2text2 {
    font-size: 2vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    margin: 1vw 5vw 0px 0px;
  }

  .toutu_row2text2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .toutu_row2text2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  .toutu_figuer {
    margin: 0;

  }

  .figuer_text {
    margin: 0;
    font-size: 4vw;
  }

  .figuer_img {
    width: 10vw;
  }

  .img_1 {
    position: absolute;
    width: 28vw;
    top: 11vw;
    right: 6%;
  }

  .dabiaoti {
    font-size: 8vw;
  }

  .dabiaoti_1 {
    font-size: 8vw;
    white-space: nowrap;
    text-align: left;
  }


  .img_2 {
    margin-top: 20%;
    margin-right: 40%;
    width: 15vw;
    overflow: hidden
  }

  .juxing {
    width: 90%;
    height: 10vw;
    background-color: #836aff;
    border-radius: 100px;

  }

  .juxingtext {
    color: #FFFFFF;
    font-size: 3vw;
    position: absolute;
    z-index: 2;
    text-align: center;

  }

  /* 头图结束  */





  /* 具有挑战性模块 */
  .tiaozhanmokuai {
    height: max-content;
  }

  .tiaozhanbtn {
    width: 10vw;
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .tiaozhancardbody {
    text-align: start;
    background-color: transparent;
    padding: 0;
  }

  .tiaozhanbigbiaoti {
    padding-top: 20px;
  }





  .rowmargin-top {
    margin-top: 4rem;
  }

  .divmargin-top {
    margin-top: 1rem;
  }

  .item_1 {
    order: 1;
  }

  .item_2 {
    order: 2;
  }

  .item_3 {
    order: 3;
  }

  .img_4 {
    position: absolute;
    left: 4vw;
    /* 设置浮动属性，如向左浮动 */
  }

  .img_5 {
    position: absolute;
    top: -1vw;
    left: -3vw;

    /* 设置浮动属性，如向左浮动 */

  }

  .text_3 {
    font-size: 1rem;
    text-align: start;
    padding-bottom: 1rem;
    color: #999999;

  }

  .text_4 {
    font-size: 3rem;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    padding: 0;
    text-align: start;
    height: auto;

  }

  .text-zuoduiqi {
    text-align: start;
  }


  /* 上线网站css */
  .webpng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 0em;
    width: clamp(100px, 13vw, 300px);
  }

  .wwwpng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    bottom: 1.5em;
    left: 0;
    width: clamp(100px, 20vw, 200px);
  }

  .shejizhiwaipng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 2em;
    width: clamp(100px, 14vw, 300px);
  }



  .shangxianwangzhan-btn_1 {
    margin-top: 30px;
    margin-right: 50px;
    border-radius: 50%;
    width: 5vw;
    height: 5vw;
    border: 2px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;

  }

  .shangxianwangzhan-btn_1:hover {
    background-color: #000000;
    transform: rotate(45deg);
  }

  .shangxianwangzhan-btn_1:hover img {
    filter: brightness(100) invert(1);
  }

  .shangxianwangzhan-img_1 {
    height: 20px;
    width: 20px;
    box-shadow: none !important;
    margin-top: 0 !important;
  }

  .card-text {
    padding-bottom: 2rem
  }

  .card-body {
    padding: 0px 20px 0px 20px;
    border: none;
  }

  .shangxianwnagzhancard {
    border-radius: 16px;
    padding: 2rem 0rem 2rem 2rem;
  }

  .shangxianwnagzhancard:hover {
    border-radius: 16px;
    background-color: #ffffff;
    transition-property: background-color, box-shadow;
    transition-duration: 1s, 1s;
    transition-timing-function: ease;
  }

  .shangxianwnagzhancard:hover img {
    transform: scale(1.05);
    transition: transform 1s ease, box-shadow 1s ease;
    box-shadow: 0px 0px 15px rgba(154, 154, 154, 0);
  }

  .shangxianwnagzhancard img {
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.735);
  }

  .shangxianwnagzhancard:not(:hover) img {
    transition: transform 1s ease;
  }

  .bigtext {
    font-size: 5rem;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 800;
  }

  .smtext {
    font-size: 1rem;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }

  .shangxianwangzhanbiaoti {
    height: 40px;
  }

  .shangxianwangzhanbiaoti svg {
    display: none;
  }

  /* 上线网站结束 */

  /* 资产 */
  .zichanbody {
    background-color: #151B29;
    padding: 5px 5px 5px 5px;
    border-radius: 30px;
    margin: 0px 20px 0px 20px;
  }

  .zichanmarquee-text {
    font-family: "Source Han Sans CN", sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 4vw;
    background-color: transparent;
    font-weight: 400;
    padding: 40px 20px 40px 20px;
    letter-spacing: 2px;
  }

  .zichaniphonetext {
    color: #151B29;
  }

  /* 资产结束 */


  /* 设计之外css */
  .img_6 {
    float: left;
    width: 90%;
    transform: translate3d(50%, -50%, 0px);
    transition: transform 4s ease, opacity 4s ease;
    opacity: 0.4;
  }


  .shejizhiwaicard:hover>.img_6 {
    transform: scale(1.1) translate3d(25%, -50%, 0px);
    opacity: 0.5;
    float: left;
  }



  .shejizhiwaibigtext {
    color: #ffffff;
    text-align: start;
    font-size: 3rem;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 500;
    margin-top: 2rem;
  }

  .shejizhiwaismtext {
    font-size: 1rem;
    color: #ffffffb5;
    margin-top: 2.8rem;
    text-align: start;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 500;
  }

  .shejizhiwaicard {
    padding: 2rem 0rem 2rem 2rem;
    text-align: start;
    overflow: hidden;
    height: 360px;

  }

  .btn_1 {
    border: 0.8px solid white;
    color: #ffffff;
    padding: 0.8rem 2rem 0.8rem 2rem;
    text-align: start;
    width: auto;
    margin-top: 1rem;
  }

  .btn_1:hover {
    background-color: #FFFFFF;
    color: black;
    text-align: start;
    width: auto;
    margin-top: 1rem;
  }

  /* 设计之外结束 */

  /* 学习探索css */
  .xuexizuomokuai {
    border-bottom: 1px solid #333333;
    height: 100px;
  }

  .xuexibiaoqian {
    width: auto;
    padding: 0.6vw 3vw 0.6vw 3vw;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 0.75px solid black;
  }

  .xuexibiaoti {
    font-size: 3vw;
    padding-left: 2vw;
  }

  .xuexizuomokuai:hover .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;

    transition: transform 1s ease;
    transform: rotate(-90deg);
  }

  .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;
    transform: rotate(-135deg);
    transition: transform 1s ease;

  }

  .hangtop {
    margin-top: 0.4vw;
  }

  .biaoqian-xuexi {
    border: 0.7px solid #999999;
    padding: 0.6rem 1.5rem 0.6rem 1.5rem;
    margin-right: 0.5rem;
  }

  .btn_2 {
    font-size: 2rem;
    height: 100px;
    color: #333333;
    transition: transform 1s ease,

  }

  .btn_2:hover {
    transform: scale(1.1) translate3d(-1%, -1%, 0px);
  }

  .qianduanxuexi_biaoti {
    font-kerning: 2rem;
  }

  .xuexitansuospanyou {
    all: initial;
    text-align: end;
    padding-left: 60%;
  }

  /* 学习探索结束 */



}

/* 电脑端 */
@media only screen and (min-width: 1025px) {

  .zuopinjibtn{
    display: none;
  }

  /* 走马灯 */
  .marquee-text {
    font-size: 1.8vw;
    background-color: transparent;
    font-weight: 400;
    padding: 20px;
    letter-spacing: 5px;
  }

  /* 头图 */
  .toutu_row4jiantou {
    position: absolute;
  }

  .toutu_row4wenzixuanzhuan {
    width: 100%;
    height: 100%;
    animation: rotateAnimation 10s linear infinite;
  }

  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .toutu_row3juxing {
    width: auto;
    height: 3vw;
    border: 1px solid rgb(0, 0, 0);
    padding: 1vw 2vw 1vw 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vw;
    font-size: 0.8vw;
    margin: 5px;
  }

  .toutu_row2text1 {
    font-size: 1.5vw;
    margin: 0px 5vw 0px 0px;
  }

  .toutu_row2text2 {
    font-size: 1vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    margin: 0px 5vw 0px 0px;
  }
  .toutu_row2text2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .toutu_row2text2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }


  .toutu_figuer {
    margin: 0;
  }

  .figuer_text {
    margin: 0;
    font-size: 2vw;
  }

  .figuer_img {
    width: 5vw;
  }

  .img_1 {
    position: absolute;
    width: 20vw;
    top: 10vw;
    right: 8vw;
  }

  .dabiaoti {
    font-size: 8vw;
    line-height: 100px;
  }

  .dabiaoti_1 {
    font-size: 9vw;
    white-space: nowrap;
  }


  .img_2 {
    margin-top: 24%;
    margin-right: 40%;
    width: 10vw;

  }

  .juxing {
    width: 20vw;
    height: 10vw;
    background-color: #836aff;
    border-radius: 100px;
  }

  /* 头图结束  */
  /* banner */

  .bannercard2 {
    height: 15vw;
  }

  .rowmargin-top {
    margin-top: 6rem;
  }

  .juxingtext {
    color: #FFFFFF;
    font-size: 3vw;
    position: absolute;
    z-index: 2;
    /* 增加一个较高的 z-index 值，确保文本在上面 */
    text-align: center;

  }

  /* banner结束 */

  /* 具有挑战性模块 */
  .tiaozhanmokuai {
    height: max-content;
  }



  .tiaozhancardbody {
    text-align: start;
    padding-left: 0;
  }


  .tiaozhanzhengwen {
    font-size: 0.8vw;
    text-align: justify;
    color: #666666;
    line-height: 1.6;
  }



  .tiaozhanbigbiaoti {
    font-size: 1.5vw;
    text-align: start;
    margin: 0;
    line-height: 2;
    font-weight: 900;
  }


  .divmargin-top {
    margin-top: 1rem;
  }


  .img_4 {
    position: absolute;
    left: 8vw;
  }

  .img_5 {
    position: absolute;
    top: -1vw;
    left: -3vw;
  }

  .text_3 {
    font-size: 1rem;
    text-align: start;
    padding-bottom: 1rem;
    color: #999999;
    height: auto;

  }

  .text_4 {
    font-size: 3.5vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    padding: 0;
    text-align: start;
    line-height: 1.2;
    height: auto;
  }


  .tiaozhancardtext {
    margin-top: 0.5vw;
    line-height: 3;
  }

  .tiaozhancardbody {
    text-align: start;
    padding-left: 0;
  }

  .tiaozhan {
    height: 460px;
  }


  /* 具有挑战性模块结束 */
  /* 视觉模块开始 */
  .xuexizuopin {
    font-weight: 300;
    font-size: 0.8vw;
    color: #ffffff90;
  }

  .xuexishuliang {
    font-weight: 600;
    font-size: 1.1vw;
    color: #ffffff;
  }

  .rounded-box {
    font-size: 0.8vw;
    width: 4vw;
    height: 4vw;
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    /* 这里设置了半透明的黑色背景，可根据需求调整颜色和透明度 */
    color: #FFFFFF;
    margin: 0;
    padding: 0;
  }


  .shijueimg {
    width: 100%;
    height: auto;
    border-radius: 6px;
    margin: 0;
    padding: 0;
  }


  .shijueicon {

    transform: scale(3);
  }

  .shijuecard {
    padding: 2vw;
    height: auto;
  }



  .shijuesmtext {
    color: #FFFFFF;
    font-size: 0.8vw;
    font-weight: 200;
  }

  .shijuebigtext {
    color: #FFFFFF;
    font-size: 2vw;
    font-weight: 700;
  }

  .shijuewwwtext {
    color: #FFFFFF;
    font-size: 0.6vw;
    font-weight: 200;

  }

  .shijuedata {
    color: #FFFFFF;
    font-size: 1.5vw;
    font-weight: 200;
    margin: 0vw 0 0vw 1vw;
  }

  .shijuebutton {
    margin: 1vw 0 0vw 0;
  }

  .shijuedown {
    margin: 1vw 0 0vw 0;
    padding: 0;
  }

  /* 视觉模块结束 */
  /* 上线网站css */
  .text-muted {
    font-size: 1vw;
  }

  .shangxianwangzhan-btn_1 {
    margin-top: 50px;
    margin-right: 50px;
    border-radius: 50%;
    width: 3vw;
    height: 3vw;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;

  }

  .shangxianwangzhan-btn_1:hover {
    background-color: #000000;
    transform: rotate(45deg);
  }

  .shangxianwangzhan-btn_1:hover img {
    filter: brightness(100) invert(1);
  }

  .shangxianwangzhan-img_1 {
    height: 20px;
    width: 20px;
    box-shadow: none !important;
    margin-top: 0 !important;
  }

  .card-body {
    padding: 20px 20px 0px 20px;
    border: none;
  }

  .text-zuoduiqi {
    text-align: start;
  }

  .shangxianwangzhanbiaoti {
    height: 40px;
  }

  .shangxianwnagzhancard {
    border-radius: 16px;
    padding: 2rem 0rem 2rem 2rem;
  }

  .shangxianwnagzhancard:hover {
    border-radius: 16px;
    background-color: #ffffff;
    transition: background-color 1s ease, box-shadow 1s ease;
  }

  .shangxianwnagzhancard:hover img {
    margin-top: 20px;
    transform: scale(1.05);
    transition: transform 1s ease, box-shadow 1s ease;
    box-shadow: 0px 0px 15px rgba(154, 154, 154, 0);
  }

  .shangxianwnagzhancard img {
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.21);
    transition: all 1s ease;
  }

  .shangxianwnagzhancard:not(:hover) img {
    margin-top: 20px;
    transform: scale(1);
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.21);
    transition: all 1s ease;
  }

  .bigtext {
    font-size: 8vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 800;
  }

  .smtext {
    font-size: 1.3vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }

  .webpng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 550px;
    bottom: 2em;
    width: clamp(300px, 10vw, 500px);
    transform: scaleX(-1);
  }

  .wwwpng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    bottom: 1.5em;
    left: 0;
    width: clamp(100px, 20vw, 200px);
  }

  .shejizhiwaipng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 0em;
    width: clamp(100px, 16vw, 300px);
  }

  .shijuemokuai {
    height: max-content;

  }

  /* 上线网站结束 */

  /* 资产展示模块 */
  .zichaniphonetext {
    color: #151B29;
  }

  .zichanmarquee-text {
    font-family: "Source Han Sans CN", sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 4vw;
    background-color: transparent;
    font-weight: 400;
    padding: 40px 20px 40px 20px;
    letter-spacing: 2px;
  }



  .zichanbody {
    background-color: #151B29;
    padding: 30px;
    border-radius: 20px;
  }

  .swiper-slide {
    border-radius: 15px;
  }

  .img-fluid {
    border-radius: 15px;
  }

  /* 资产展示结束 */


  /* 设计之外css */
  .card-textday {
    padding-top: 1.9vw;
  }

  .img_6 {

    width: 50%;
    transform: translate3d(60%, -70%, 0px);
    transition: transform 4s ease, opacity 4s ease;
    opacity: 0.4;
  }

  .shejizhiwaicard:hover>.img_6 {
    transform: scale(1.2) translate3d(60%, -55%, 0px);
    opacity: 0.8;
  }

  .shejizhiwaibigtext {
    color: #ffffff;
    text-align: start;
    font-size: 3rem;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 500;
    margin-top: 2rem;
  }

  .shejizhiwaismtext {
    font-size: 1rem;
    color: #ffffffb5;
    margin-top: 2.8rem;
    text-align: start;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 500;
  }

  .shejizhiwaicard {
    padding: 2rem 0rem 2rem 6rem;
    text-align: start;
    overflow: hidden;
    height: 340px;

  }

  .btn_1 {
    border: 0.8px solid white;
    color: #ffffff;
    padding: 0.8rem 2rem 0.8rem 2rem;
    text-align: start;
    width: auto;
    margin-top: 1rem;
  }

  .btn_1:hover {
    background-color: #FFFFFF;
    color: black;
    text-align: start;
    width: auto;
    margin-top: 1rem;
  }

  /* 设计之外结束 */

  /* 学习探索css */
  .xuexizuomokuai {
    border-bottom: 1px solid #333333;
    height: 150px;
  }

  .xuexibiaoqian {
    width: auto;
    padding: 0.6vw 3vw 0.6vw 3vw;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 0.75px solid black;
  }

  .xuexibiaoti {
    font-size: 3vw;
    padding-left: 2vw;
  }

  .xuexizuomokuai:hover .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;

    transition: transform 1s ease;
    transform: rotate(-90deg);
  }

  .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;
    transform: rotate(-135deg);
    transition: transform 1s ease;

  }

  .hangtop {
    margin-top: 0.4vw;
  }

  .biaoqian-xuexi {
    border: 0.7px solid #999999;
    padding: 0.6rem 1.5rem 0.6rem 1.5rem;
    margin-right: 0.5rem;
  }

  .btn_2 {
    font-size: 2rem;
    height: 100px;
    color: #333333;
    transition: transform 1s ease,

  }

  .btn_2:hover {
    transform: scale(1.1) translate3d(-1%, -1%, 0px);
  }

  .qianduanxuexi_biaoti {
    font-kerning: 2rem;
  }

  .xuexitansuospanyou {
    all: initial;
    text-align: end;
    padding-left: 60%;
  }

  /* 学习探索结束 */



}




/* 超大屏幕电脑端 */
@media only screen and (min-width: 2048px) {

  /* 网页一级标题样式 */
  .text_3 {
    font-size: 1vw;
    text-align: start;
    padding-bottom: 2rem;
    color: #999999;
    height: auto;

  }

  .wwwpng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    bottom: 1.5em;
    left: 0;
    width: clamp(80px, 10vw, 160px);
  }

  .img_4 {
    width: 10%;
    transform: translate3d(-16.7468px, -4.1867px, 0px);
  }

  .text_4 {
    font-size: 3.5vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    padding: 0;
    text-align: start;
    line-height: 1;
    height: auto;
  }

  /* 走马灯 */
  .marquee-text {
    font-size: 1.8vw;
    background-color: transparent;
    font-weight: 400;
    padding: 20px;
    letter-spacing: 5px;
  }

  /* 网页一级标题样式结束 */
  .divmargin-top {
    margin-top: 2rem;
  }

  .rowmargin-top {
    margin-top: 6rem;
  }

  /* 头图 */
  .toutu_row3juxing {
    width: auto;
    height: 3vw;
    border: 1px solid rgb(0, 0, 0);
    padding: 0.6vw 0.8vw 0.6vw 0.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vw;
    font-size: 0.7vw;
    margin: 5px;
  }

  .toutu_row2text1 {
    font-size: 1.5vw;
  }

  .toutu_row2text2 {
    font-size: 1vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
  }

  .toutu_row2text2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .toutu_row2text2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  .toutu_figuer {
    margin: 0;

  }

  .figuer_text {
    margin: 0;
    font-size: 2vw;
  }

  .img_1 {
    position: absolute;
    width: 14vw;
    top: 10vw;
    right: 24vw;
  }

  .dabiaoti {
    font-size: 5.5vw;
    text-align: start;
  }

  .dabiaoti_1 {
    font-size: 5vw;
    white-space: nowrap;
  }


  .img_2 {
    margin-top: 17%;
    margin-right: 40%;
    width: 10vw;
  }

  .juxing {
    width: 13vw;
    height: 7vw;
    background-color: #836aff;
    border-radius: 100px;
  }

  .juxing::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.42));
    z-index: 2;
  }

  .juxingtext {
    color: #FFFFFF;
    font-size: 3vw;
    position: absolute;
    z-index: 2;
    text-align: center;
  }

  /* 头图结束  */


  /* 具有挑战性模块 */
  .tiaozhanmokuai {
    height: max-content;
  }

  .tiaozhancardtext {
    margin-top: 0vw;
    line-height: 2;
    margin: 20px 0 0 0;
  }

  .tiaozhancardbody {
    text-align: start;
    padding-left: 0;
    margin: 0;
  }

  .tiaozhanbigbiaoti {
    font-size: 1.3vw;
    margin: 0;
  }

  .tiaozhanzhengwen {
    font-size: 0.8vw;
    text-align: justify;
    color: #666666;
    margin: 0 0 10px 0;
  }



  /* 具有挑战性模块结束 */

  /* 上线网站css */

  .text-muted {
    font-size: 0.6vw;
  }

  .shangxianwangzhan-btn_1 {
    margin-top: 50px;
    margin-right: 50px;
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;

  }

  .shangxianwangzhan-btn_1:hover {
    background-color: #000000;
    transform: rotate(45deg);
  }

  .shangxianwangzhan-btn_1:hover img {
    filter: brightness(100) invert(1);
  }

  .shangxianwangzhan-img_1 {
    height: 20px;
    width: 20px;
    box-shadow: none !important;
    margin-top: 0 !important;
  }

  .card-body {
    padding: 20px 20px 0px 20px;
    border: none;
  }

  .text-zuoduiqi {
    text-align: start;
  }

  .shangxianwangzhanbiaoti {
    height: 40px;
  }

  .shangxianwnagzhancard {
    border-radius: 16px;
    padding: 2rem 0rem 2rem 2rem;
  }

  .shangxianwnagzhancard:hover {
    border-radius: 16px;
    background-color: #ffffff;
    transition: background-color 1s ease, box-shadow 1s ease;
  }

  .shangxianwnagzhancard:hover img {
    margin-top: 20px;
    transform: scale(1.05);
    transition: transform 1s ease, box-shadow 1s ease;
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.21);
  }

  .shangxianwnagzhancard img {
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.21);
    transition: all 1s ease;

  }

  .shangxianwnagzhancard:not(:hover) img {
    margin-top: 20px;
    transform: scale(1);
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.735);
    transition: all 1s ease;

  }

  .bigtext {
    font-size: 5vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 800;
  }

  .smtext {
    font-size: 1vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }

  .shangxianwangzhansmtext {
    font-size: 5vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }

  /* 上线网站结束 */


  /* 资产展示模块 */
  .zichanmarquee-text {
    font-family: "Source Han Sans CN", sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 2vw;
    background-color: transparent;
    font-weight: 400;
    padding: 40px 20px 40px 20px;
    letter-spacing: 2px;
  }

  .zichaniphonetext {
    display: none;
  }

  .shejizhiwaipng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 0em;
    width: clamp(100px, 16vw, 300px);
  }

  .zichanbody {
    background-color: #151B29;
    padding: 30px;
    border-radius: 20px;
  }

  .swiper-slide {
    border-radius: 15px;
  }

  .img-fluid {
    border-radius: 15px;
  }

  /* 资产结束 */


  /* 设计之外css */
  .card-textday {
    padding-top: 1.9vw;
  }

  .card-textday {
    padding-top: 1.9vw;
  }

  .img_6 {
    width: 50%;
    transform: translate3d(75%, -70%, 0px);
    transition: transform 4s ease, opacity 4s ease;
    opacity: 0.4;
  }

  .shejizhiwaicard:hover>.img_6 {
    transform: scale(1.2) translate3d(60%, -55%, 0px);
    opacity: 0.8;
  }

  .shejizhiwaibigtext {
    color: #ffffff;
    text-align: start;
    font-size: 2vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 500;
    margin-top: 0.5vw;
  }

  .shejizhiwaismtext {
    font-size: 0.9vw;
    color: #ffffffb5;
    margin-top: 2.8rem;
    text-align: start;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }

  .shejizhiwaicard {
    padding: 3vw 0rem 1.8vw 3vw;
    text-align: start;
    overflow: hidden;
    height: 18.5vw;
  }

  .btn_1 {
    border: 0.8px solid white;
    color: #ffffff;
    padding: 0.8rem 2rem 0.8rem 2rem;
    text-align: start;
    width: auto;
    margin-top: 1vw;
    font-size: 1.5vw;
  }

  .btn_1:hover {
    background-color: #FFFFFF;
    /* 鼠标悬停时的背景颜色  */
    color: black;
    /* 鼠标悬停时的文字颜色  */
    text-align: start;
    width: auto;
    margin-top: 1rem;
  }

  /* 设计之外结束 */

  /* 学习探索css */
  .xuexizuomokuai {
    border-bottom: 1px solid #333333;
    height: 150px;
  }

  .xuexibiaoqian {
    width: auto;
    padding: 0.6vw 3vw 0.6vw 3vw;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 0.75px solid black;
  }

  .xuexibiaoti {
    font-size: 2vw;
    padding-left: 2vw;
  }

  .xuexizuomokuai:hover .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;

    transition: transform 1s ease;
    transform: rotate(-90deg);
  }

  .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;
    transform: rotate(-135deg);
    transition: transform 1s ease;

  }

  .hangtop {
    margin-top: 0.4vw;
  }

  .biaoqian-xuexi {
    border: 0.7px solid #999999;
    padding: 0.6rem 1.5rem 0.6rem 1.5rem;
    margin-right: 0.5rem;
  }

  .btn_2 {
    font-size: 2rem;
    height: 100px;
    color: #333333;
    transition: transform 1s ease,

  }

  .btn_2:hover {
    transform: scale(1.1) translate3d(-1%, -1%, 0px);
  }

  .qianduanxuexi_biaoti {
    font-kerning: 2rem;
  }

  .xuexitansuospanyou {
    all: initial;
    text-align: end;
    padding-left: 60%;
  }

  /* 学习探索结束 */



}

@media only screen and (min-width: 3072px) {
  .rowmargin-top {
    margin-top: 6rem;
  }

  .container {
    max-width: 2048px;
    /* 或者其他你想要的宽度值 */
  }



  /* 作品集按钮 */
  .zuopinjibtn {
   display: none;
  }



  /* 网页一级标题样式 */
  .img_4 {

    width: 10%;
    transform: translate3d(-16.7468px, -4.1867px, 0px);

  }

  .webpng {
    position: absolute;
    margin: 0 auto;

    right: 0;
    left: 580px;
    bottom: 0;
    width: clamp(300px, 10vw, 500px);
    transform: scaleX(-1);
  }

  .wwwpng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    bottom: 1.5em;
    left: 0;
    width: clamp(100px, 20vw, 200px);
  }

  .text_3 {
    font-size: 1vw;
    text-align: start;
    padding-bottom: 2rem;
    color: #999999;
    height: auto;

  }

  .text_4 {
    font-size: 3.5vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    padding: 0;
    text-align: start;
    line-height: 1;
    height: auto;
  }

  /* 网页一级标题样式结束 */
  .divmargin-top {
    margin-top: 2rem;
  }

  /* 头图 */
  .dabiaoti {
    font-size: 7vw;
    text-align: start;
  }

  .dabiaoti_1 {
    font-size: 7vw;
    white-space: nowrap;
  }

  .img_1 {
    position: absolute;
    width: 13vw;
    top: 9vw;
    right: 20vw;
  }

  .img_2 {

    /* 调整 SVG 距离文字的顶部偏移量 */
    margin-top: 17%;
    margin-right: 45%;
    width: 10vw;

  }

  .toutu_row3juxing {
    width: auto;
    height: 3vw;
    border: 1px solid rgb(0, 0, 0);
    padding: 0.5vw 1vw 0.5vw 0.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vw;
    font-size: 0.7vw;
    margin: 5px;

  }

  .toutu_row2text1 {
    font-size: 1.5vw;
  }

  .toutu_row2text2 {
    font-size: 1vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);

  }

  .toutu_row2text2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .toutu_row2text2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  .toutu_figuer {
    margin: 0;
  }

  .figuer_text {
    margin: 0;
    font-size: 2vw;
  }

  .juxing {
    width: 13vw;
    height: 7vw;
    background-color: #836aff;
    border-radius: 500px;
    margin-top: 1vw;
  }

  .juxing::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.42));
    /* 从上到下的渐变，上面透明度 0，下面透明度 0.3（30%） */
    z-index: 2;
  }

  .row3juxingtop {
    margin-top: 2vw;
  }

  /* 头图结束 */





  /* 具有挑战性模块 */
  .tiaozhanmokuai {
    height: max-content;
  }

  .tiaozhancardtext {
    margin-top: 0;
    line-height: 3;
  }

  .tiaozhancardbody {
    text-align: start;
    padding-left: 0;
  }

  .tiaozhanbigbiaoti {
    font-size: 1.3vw;
    height: 3vw;
  }

  .tiaozhanzhengwen {
    font-size: 0.8vw;
    text-align: justify;
    color: #666666;
    line-height: 1.6;
  }

  /* 具有挑战项目结束 */
  /* 视觉展示模块 */
  .shijuebigtext {
    color: #FFFFFF;
    font-size: 1.6vw;
    font-weight: 700;
  }

  /* 视觉展示结束 */
  /* 上线网站css */

  .text-muted {
    font-size: 0.8vw;
  }

  .shangxianwangzhan-btn_1 {
    margin-top: 50px;
    margin-right: 50px;
    border-radius: 50%;
    width: 3vw;
    height: 3vw;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
  }

  .shangxianwangzhan-btn_1:hover {
    background-color: #000000;
    transform: rotate(45deg);
  }

  .shangxianwangzhan-btn_1:hover img {
    filter: brightness(100) invert(1);
  }

  .shangxianwangzhan-img_1 {
    height: 30px;
    width: 30px;
    box-shadow: none !important;
    margin-top: 0 !important;
  }

  .card-body {
    padding: 20px 20px 0px 20px;
    border: none;
  }

  .text-zuoduiqi {
    text-align: start;
  }

  .shangxianwangzhanbiaoti {
    height: 40px;
  }

  .shangxianwnagzhancard {
    border-radius: 16px;
    padding: 2rem 0rem 2rem 2rem;
  }

  .shangxianwnagzhancard:hover {
    border-radius: 16px;
    background-color: #ffffff;
    transition: background-color 1s ease, box-shadow 1s ease;
  }

  .shangxianwnagzhancard:hover img {
    margin-top: 20px;
    transform: scale(1.05);
    transition: transform 1s ease, box-shadow 1s ease;
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.21);
  }

  .shangxianwnagzhancard img {
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.21);
    transition: all 1s ease;

  }

  .shangxianwnagzhancard:not(:hover) img {
    margin-top: 20px;
    transform: scale(1);
    box-shadow: 0px 0px 15px rgba(111, 111, 111, 0.21);
    transition: all 1s ease;

  }

  .bigtext {
    font-size: 5vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 800;
  }

  .smtext {
    font-size: 1vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }

  .shangxianwangzhansmtext {
    font-size: 5vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }


  .card-title {
    font-size: 1.4vw;
    word-wrap: break-word;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    padding-top: 1vw;
  }

  .card-text {
    word-wrap: break-word;
    font-size: 1.1vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 0;
  }

  /* 上线网站结束 */

  /* 资产模块 */
  .zichanmarquee-text {
    font-family: "Source Han Sans CN", sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 2vw;
    background-color: transparent;
    font-weight: 400;
    padding: 40px 20px 40px 20px;
    letter-spacing: 2px;
  }

  .zichanSwiper {
    height: 350px;
  }

  .zichaniphonetext {
    display: none;
  }

  .zichanbody {
    background-color: #151B29;
    padding: 30px;
    border-radius: 20px;
  }

  .swiper-slide {
    border-radius: 15px;
    /* 圆角 */
  }

  .img-fluid {
    border-radius: 15px;
  }

  /* 资产结束 */

  /* 设计之外板块 */
  .shejizhiwaipng {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 0em;
    width: clamp(100px, 16vw, 300px);
  }

  .card-textday {
    padding-top: 1.9vw;
  }

  .img_6 {
    width: 50%;
    transform: translate3d(75%, -70%, 0px);
    transition: transform 4s ease, opacity 4s ease;
    opacity: 0.4;
  }

  .shejizhiwaicard:hover>.img_6 {
    transform: scale(1.2) translate3d(60%, -55%, 0px);
    opacity: 0.8;
  }

  .shejizhiwaibigtext {
    color: #ffffff;
    text-align: start;
    font-size: 2vw;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 500;
    margin-top: 0.5vw;
  }

  .shejizhiwaismtext {
    font-size: 0.9vw;
    color: #ffffffb5;
    margin-top: 2.8rem;
    text-align: start;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
  }

  .shejizhiwaicard {
    padding: 4vw 0rem 3vw 4vw;
    text-align: start;
    overflow: hidden;
    height: 18vw;
  }

  .btn_1 {
    border: 0.8px solid white;
    color: #ffffff;
    padding: 0.8rem 2rem 0.8rem 2rem;
    text-align: start;
    width: auto;
    margin-top: 1vw;
    font-size: 1.5vw;
  }

  .btn_1:hover {
    background-color: #FFFFFF;
    /* 鼠标悬停时的背景颜色  */
    color: black;
    /* 鼠标悬停时的文字颜色  */
    text-align: start;
    width: auto;
    margin-top: 1rem;
  }

  /* 设计之外结束 */



  /* 学习探索css */
  .xuexizuomokuai {
    border-bottom: 1px solid #333333;
    height: 200px;
  }

  .xuexibiaoqian {
    width: auto;
    padding: 0.6vw 3vw 0.6vw 3vw;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 0.75px solid black;
    font-size: 1vw;
  }

  .xuexibiaoti {
    font-size: 2vw;
    padding-left: 2vw;
  }

  .xuexizuomokuai:hover .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;

    transition: transform 1s ease;
    transform: rotate(-90deg);
  }

  .xuexijiantou {
    width: 2vw;
    height: auto;
    padding: 0;
    transform: rotate(-135deg);
    transition: transform 1s ease;

  }

  .hangtop {
    margin-top: 0.4vw;
  }

  .biaoqian-xuexi {
    border: 0.7px solid #999999;
    padding: 0.6rem 1.5rem 0.6rem 1.5rem;
    margin-right: 0.5rem;
  }

  .btn_2 {
    font-size: 2rem;
    height: 100px;
    color: #333333;
    transition: transform 1s ease,

  }

  .btn_2:hover {
    transform: scale(1.1) translate3d(-1%, -1%, 0px);
    /* 鼠标悬停时同时放大并保持平移效果  */
  }

  .qianduanxuexi_biaoti {
    font-kerning: 2rem;
  }

  .xuexitansuospanyou {
    all: initial;
    text-align: end;
    padding-left: 60%;
  }



  /* 学习探索结束 */
}


@media only screen and (min-width: 4096px) {
  .rowmargin-top {
    margin-top: 12rem;
  }

  .divmargin-top {
    margin-top: 8rem;
  }

  .container {
    max-width: 3072px;
    /* 或者其他你想要的宽度值 */
  }

  .zuopinjibtn {
    display: none;
  }


  .dabiaoti {
    font-size: 8vw;
    text-align: start;
  }

  .dabiaoti_1 {
    font-size: 8vw;
    white-space: nowrap;
  }

  .img_1 {
    position: absolute;
    width: 14vw;
    top: 8vw;
    right: 14vw;
  }

  .toutu_row3juxing {
    width: auto;
    height: 3vw;
    border: 1px solid rgb(0, 0, 0);
    padding: 0.5vw 1vw 0.5vw 0.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vw;
    font-size: 0.7vw;
    margin: 5px;

  }



  .toutu_row2text1 {
    font-size: 1.5vw;
  }

  .toutu_row2text2 {
    font-size: 1vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);

  }

  .toutu_row2text2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .toutu_row2text2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }


  .toutu_figuer {
    margin: 0;

  }

  .figuer_text {
    margin: 0;
    font-size: 2vw;
  }




  .img_2 {

    /* 调整 SVG 距离文字的顶部偏移量 */
    margin-top: 17%;
    margin-right: 40%;
    width: 10vw;

  }

  .juxing {
    width: 13vw;
    height: 7vw;
    background-color: #836aff;
    border-radius: 500px;
    margin-top: 1vw;

  }

  .juxing::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.42));
    z-index: 2;
  }

  .row3juxingtop {
    margin-top: 2vw;
  }


}
</style>


<script>

// 引入srocllreveal
import ScrollReveal from 'scrollreveal';
// 引入animate
import 'animate.css';

import { useRouter } from 'vue-router';

//滚动字幕
import { Marquee, loop, } from 'dynamic-marquee';
import '../bootstrap/css/swiperstyle.css';
// swiper 插件
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Autoplay, Pagination, Navigation, Virtual, EffectFade, } from 'swiper/modules';

// swiper 插件样式
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/virtual';
import 'swiper/css/thumbs';
import 'swiper/css/effect-fade';
// gasp动画
import { gsap } from 'gsap';
import { Power4 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useRouter();
    const goToPortfolio = () => {
      router.push({ name: 'portfolio' });
    };
    const goToIcon = () => {
      router.push({ name: 'Icon' });
    };
    const goToHuaxia = () => {
      router.push({ name: 'Huaxia' });
    };
    const goToShaYu = () => {
      router.push({ name: 'ShaYu' });
    };
    const goToYiBaiShun = () => {
      router.push({ name: 'YiBaiShun' });
    };
    const goToFenBi = () => {
      router.push({ name: 'FenBi' });
    };
    const goToHome = () => {
      router.push({ name: 'home' });
    };
    const goToHaibaoZhongqiu = () => {
      router.push({ name: 'Haibao_Zhongqiu' });
    };
    const goTSSaaSWeb = () => {
      router.push({ name: 'SaaSWeb' });
    };
    const goToHuXiaWEB = () => {
      router.push({ name: 'HuXiaWEB' });
    };
    return {
      goToPortfolio,
      goToIcon,
      goToHuaxia,
      goToShaYu,
      goToYiBaiShun,
      goToFenBi,
      goToHome,
      goToHaibaoZhongqiu,
      goTSSaaSWeb,
      goToHuXiaWEB,
      modules: [Autoplay, FreeMode, Autoplay, Pagination, Navigation, Virtual, EffectFade,],
    };

  },

  data() {
    return {
     
      bgColor: '#ECEFF8',
      animationSrc: 'https://lottie.host/your-animation-id/your-animation.json',
      url: '/img/zhongqiu/zhongqiu_sm.png',
      srcList: [
        '/img/zhongqiu/zhongqiu_1.png',
        '/img/zhongqiu/zhongqiu_2.png',
        '/img/zhongqiu/zhongqiu_3.png',
        '/img/zhongqiu/zhongqiu_3.png',
      ]
    };
  },

  mounted() {


    const sr = ScrollReveal();
// 第一个效果应用于具有 class="right-effect" 的元素
sr.reveal('.right-effect', {
    duration: 3000,
    origin: 'right',
    distance: '300px',
    afterReveal: (el) => {
        el.classList.add('animate__fadeInLeft');
    }
});

// 第二个效果应用于具有 class="bottom-effect" 的元素
sr.reveal('.bottom-effect', {
    duration: 3000,
    origin: 'bottom',
    distance: '300px',
    afterReveal: (el) => {
        el.classList.add('animate__fadeInUp');
    }
});
    
    //js动画 魔法

    var magnets = document.querySelectorAll('.magnetic');
    var strength = 50;

    magnets.forEach((magnet) => {
      magnet.addEventListener('mousemove', moveMagnet);
      magnet.addEventListener('mouseout', function (event) {
        gsap.to(event.currentTarget, 1, { x: 0, y: 0, ease: Power4.easeOut });
      });
    });

    function moveMagnet(event) {
      var magnetButton = event.currentTarget;
      var bounding = magnetButton.getBoundingClientRect();
      gsap.to(magnetButton, 1, {
        x: (((event.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * strength,
        y: (((event.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * strength,
        ease: Power4.easeOut,
      });
    }


    // 滚动监听


    
    gsap.from('.gsap-toutu__newstart', {
      y: -120,
      x: 0,
      rotate: -60,
      duration: .7,
      // opacity: .7,
      ease: 'Power3.out',
      scrollTrigger: {
        trigger: '.gsap-toutu__wrapper',
        scrub: 1
      }
    })
  
    gsap.from('.gsap-recent__sticker', {
      y: -80,
      x: 0,
      duration: .8,

      ease: 'Power3.out',
      scrollTrigger: {
        trigger: '.gsap-recent__header',
        scrub: 1
      }
    })



    gsap.from('.gsap-www__sticker', {
      y: -80,
      x: 0,
      duration: .8,
      // opacity: .7,
      ease: 'Power3.out',
      scrollTrigger: {
        trigger: '.gsap-www__header',
        scrub: 1
      }
    })



    gsap.from('.gsap-ufo__sticker', {
      y: 80,
      x: 0,
      ease: 'Power3.out',
      scrollTrigger: {
        trigger: '.gsap-ufo__header',
        scrub: 1
      },
    })


    gsap.from('.gsap-xuexi__sticker', {
      y: 0,
      x: -100,
      duration: .8,
      // opacity: .7,
      ease: 'Power3.out',
      scrollTrigger: {
        trigger: '.gsap-xuexi__title',
        scrub: 1
      }
    })

    gsap.from('.gsap-collections__sticker', {
      y: -25,
      x: -100,
      duration: .8,
      // opacity: .7,
      ease: 'Power3.out',
      scrollTrigger: {
        trigger: '.gsap-collections__title',
        scrub: 1
      }
    })






    // 头图
    const $marquee = document.getElementById('marquee');
    const marquee = (window.m = new Marquee($marquee, {
      rate: -40,
      startOnScreen: true, // start on screen
    }));

    window.l = loop(
      marquee,
      [
        function () {
          return 'UI/UX';
        },
        function () {
          return 'WEB';
        },
        function () {
          return '3D VISION';
        },
        function () {
          return 'FRONT END';
        },
        function () {
          return 'DESIGNER';
        },
        function () {
          return 'FREELANCER';
        },
      ],
      () => {
        const $separator = document.createElement('div');
        $separator.innerHTML = '&nbsp•&nbsp';
        return $separator;
      },
    );
    //资产
    const $newMarqueeContainer = document.getElementById('new-marquee-container');
    const newMarquee = (window.nm = new Marquee($newMarqueeContainer, {
      rate: -80, // 可以设置不同的速度
      startOnScreen: true,
    }));

    window.nl = loop(
      newMarquee,
      [
        function () {
          return 'ICON';
        },
        function () {
          return 'WEB';
        },
        function () {
          return 'APP';
        },
        function () {
          return '3D';
        },
      ],
      () => {
        const $newSeparator = document.createElement('div');
        $newSeparator.innerHTML = ' &nbsp&nbsp🌈&nbsp&nbsp ';
        return $newSeparator;
      },
    );



  },



  methods: {




    goToDocs() {
      // 假设部署在服务器上时，根目录相对路径依然有效
      window.location.href = '/docs/index.html';
    },
  },
};



</script>