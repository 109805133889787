/*
 * @Author: zzw131 750865560@qq.com
 * @Date: 2024-08-20 16:11:47
 * @LastEditors: zzw131 750865560@qq.com
 * @LastEditTime: 2024-11-01 10:41:23
 * @FilePath: /rio/Users/zouzhiwei/Desktop/myvue2/myvue/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
router.beforeEach((to, from, next) => {
    if (from.name === null && to.path!== from.path) {
      // 判断是刷新操作，页面从无路由状态进入新路由
      window.scrollTo(0, 0);
    }
    next();
  });
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// 引入 jquery
import $ from 'jquery';
window.$ = $;
// 引入 Swiper 自定义元素注册函数
// import { register } from 'swiper/element/bundle';
// 注册 Swiper 自定义元素
// register();
// 引入 dynamic-marquee
import { Marquee } from 'dynamic-marquee';
//Lottie
import Vue3Lottie from 'vue3-lottie'
 // DotLottieVue 
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'





const app = createApp(App);

app.component('DotLottieVue', DotLottieVue);
app.use(DotLottieVue);
app.use(Vue3Lottie);
app.use(store);
app.use(router);
app.use(ElementPlus);
// 将 Marquee 构造函数挂载到全局，以便在组件中使用
app.config.globalProperties.$dynamicMarquee = Marquee;


app.mount('#app');
