/*
 * @Author: zzw131 750865560@qq.com
 * @Date: 2024-08-02 08:38:29
 * @LastEditors: zzw131 750865560@qq.com
 * @LastEditTime: 2024-10-28 15:23:00
 * @FilePath: /myvue/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { createRouter, createWebHistory } from 'vue-router';


import HomeView from '../views/HomeView.vue';

//作品集导航
import PortfolioView from '../views/PortfolioView.vue';
//3D合集
import Icon from '../views/3Dicon.vue';
//华夏基金作品
import Huaxia from '../views/HuaXiaJiJin.vue';
//医百顺作品
import YiBaiShun from '../views/YiBaiShun.vue';
//鲨鱼作品
import ShaYu from '../views/ShaYu.vue';
//粉笔作品
import FenBi from '../views/FenBi.vue';
//粉笔作品
import Haibao_Zhongqiu from '../views/Haibao-Zhongqiu.vue';
//SaaSWeb
import SaaSWeb from '../views/SaaSWeb.vue';
// HuXiaWEB
import HuXiaWEB from '../views/HuXiaWEB.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
 // HuXiaWEB
 {
  path: '/HuXiaWEB',
   name: 'HuXiaWEB', 
   component: HuXiaWEB
},
 // SaaSWeb
 {
  path: '/SaaSWeb',
   name: 'SaaSWeb', 
   component: SaaSWeb
},
 // 添加作品集路由
 {
  path: '/portfolio',
   name: 'portfolio', 
   component: PortfolioView
},
 // 添加3d图标
 {
  path: '/Icon',
   name: 'Icon', 
   component: Icon
},
 // 添加华夏项目
 {
  path: '/Huaxia',
   name: 'Huaxia', 
   component: Huaxia
},

 // 添加华夏项目
 {
  path: '/FenBi',
   name: 'FenBi', 
   component: FenBi
},

 // 添加华夏项目
 {
  path: '/YiBaiShun',
   name: 'YiBaiShun', 
   component: YiBaiShun
},
 // 添加鲨鱼项目
 {
  path: '/ShaYu',
   name: 'ShaYu', 
   component: ShaYu
},
 // 添加中秋海报详情页
 {
  path: '/Haibao_Zhongqiu',
   name: 'Haibao_Zhongqiu', 
   component: Haibao_Zhongqiu
},
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to, from, next) => {
  // 在每次路由切换时，将页面滚动位置重置到顶部
  window.scrollTo(0, 0);
  next();
});
export default router;