<!--
 * @Author: zzw131 750865560@qq.com
 * @Date: 2024-09-06 13:50:47
 * @LastEditors: zzw131 750865560@qq.com
 * @LastEditTime: 2024-10-28 15:30:25
 * @FilePath: /rio/Users/zouzhiwei/Desktop/myvue2/myvue/src/views/3DIcon.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%Ah1
-->
<template>
    <img class="bg-gradient" src="../bootstrap/img/gradient2.png" alt="">

    <div class="bg-noise"></div>
    <div class="bgyanse"></div>
    <!-- 文字滚动 -->
    <div class="marquee-text js-marquee-info border-bottom" id="marquee"> </div>
    <!-- 首页按钮 -->
    <button @click="goToHome" class="btn btn-outline-dark tiaozhanbtn_Prev"><i class="bi bi-house-fill"></i></button>

    <!-- <p class="smtext"> 项目预览</div>
        <p style="margin-bottom: 50px;" class="bigtext"> 粉笔教育</div> -->

    <div class="zhishi">

        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-mouse"
            viewBox="0 0 16 16">
            <path
                d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm4 8a4 4 0 0 1-8 0V5a4 4 0 1 1 8 0v6zM8 0a5 5 0 0 0-5 5v6a5 5 0 0 0 10 0V5a5 5 0 0 0-5-5z" />
        </svg><br>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="36" fill="currentColor" class="bi bi-arrow-down"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
        </svg>
    </div>

    <div class="container">
        <p class="smtext"> 网站设计</p>
        <p class="bigtext">华夏基金官方网站</p>

        <div  class="row  iphone-bianju">
          <div  class="row mokuai">


    
            
   
            <img style="pointer-events: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;" class="img-fluid col-12 img" src="../bootstrap/img/HuaxiaWEB.png" alt="">
                </div>
     
    </div>
    </div>

 
    

    
</template>
<style scoped>

.tiaozhanbtn_Prev {
    border: none;
    height: 40px;
    width: 40px;
    text-align: center;
    background-color: #000000;
    color: #ffffff;
    border-radius: 10px;
    transition: all 1s ease;
    transform: scale(1.2);
    justify-content: center;
    align-items: center;
    /* 或者 50% */
    position: fixed;
    bottom: 130px;
    right: 40px;
    z-index: 999;
}


@media only screen and (max-width: 768px) {
    .smtext {
    font-size: 20px;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 5vw 0 0;
}

.bigtext {
    font-size: clamp(48px, 5vw, 100px);
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 30px 0 0 0;
}
    .img{
        padding: 0;
        margin: 0px 0 2vw 0; 
        border-radius: 10px;
    }
    .iphone-bianju{
        padding: 0 10px 0 10px;
    }
    .mokuai{
        margin: 5vw 0 0 0;
        border-radius: 20px;
        padding:10px;
        background-color: #333333;
    }

    .zhishi {
        display: none;
    }

    /* 头图走马灯 */
    .marquee-text {
        font-size: 0.1vw;
        background-color: transparent;
        padding: 0;
        letter-spacing: 0px;
        color: #33333300;
        height: 1px;
        border-bottom: 0;
    }

    .yuanxing {
        color: #FFFFFF;
        width: clamp(160px, 20vw, 200px);
        height: clamp(160px, 20vw, 200px);
        background-color: rgb(0, 0, 0);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 40px 0 0 0;

    }
}




@media only screen and (min-width: 769px) {
    .smtext {
    font-size: 20px;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 5vw 0 0;
}

.bigtext {
    font-size: clamp(48px, 5vw, 100px);
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 30px 0 0 0;
}
    .img{
        padding: 0;margin: 0px 0 1vw 0; 
        border-radius: 15px;
    }
    .mokuai{
        margin: 5vw 0 0 0;
        border-radius: 30px;
        padding:30px;
        background-color: #333333;
    }
    .zhishi {
        position: fixed;
        bottom: 300px;
        right: 80px;
        z-index: 999;
        text-align: center;
    }

    /* 走马灯 */
    .marquee-text {
        font-size: 1.8vw;
        background-color: transparent;
        font-weight: 400;
        padding: 20px;
        letter-spacing: 5px;
    }

    .yuanxing {
        color: #FFFFFF;
        width: clamp(160px, 20vw, 200px);
        height: clamp(160px, 20vw, 200px);
        background-color: rgb(0, 0, 0);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .yuanxing:hover {
        background: #4B2DD0;
    }
}

/* 背景 */
.bg-noise {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-image: url(../bootstrap/img/noise.gif);
    background-position: 0 0, 0 0;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    opacity: .05;
}

.bg-gradient {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100vh;
    background-image: url(../bootstrap/img/gradient2.jpg);
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;

}

.bg-gradient::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 15vh;
    background: linear-gradient(180deg, rgba(236, 239, 248, 0) 11.11%, #EDEFF8 100%);
}

.bgyanse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f7f8ff;
    z-index: -3;
    /* 确保在.bg-noise 下面 */
}



@media only screen and (min-width: 3072px) {
    .smtext {
    font-size: 50px;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 5vw 0 0;
}

.bigtext {
    font-size: clamp(48px, 5vw, 130px);
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 30px 0 0 0;
}
  .container {
    max-width: 2048px;
    /* 或者其他你想要的宽度值 */
  }
}

@media only screen and (min-width: 4096px) {
    .smtext {
    font-size: 50px;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 5vw 0 0;
}

.bigtext {
    font-size: clamp(48px, 5vw, 160px);
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 30px 0 0 0;
}
  .container {
    max-width: 3072px;
    /* 或者其他你想要的宽度值 */
  }
}
</style>


<script>
//滚动字幕
import { Marquee, loop, } from 'dynamic-marquee';
import { gsap } from 'gsap';
import { Power4 } from 'gsap';
export default {
   
    setup() {
        return {

        };
    },

    methods: {
        goToHome() {
            this.$router.push({ path: '/' });
        },


      

    },

    mounted() {

        //js动画 魔法

        var magnets = document.querySelectorAll('.magnetic');
        var strength = 50;

        magnets.forEach((magnet) => {
            magnet.addEventListener('mousemove', moveMagnet);
            magnet.addEventListener('mouseout', function (event) {
                gsap.to(event.currentTarget, 1, { x: 0, y: 0, ease: Power4.easeOut });
            });
        });

        function moveMagnet(event) {
            var magnetButton = event.currentTarget;
            var bounding = magnetButton.getBoundingClientRect();
            gsap.to(magnetButton, 1, {
                x: (((event.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * strength,
                y: (((event.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * strength,
                ease: Power4.easeOut,
            });
        }
        //头图
        const $marquee = document.getElementById('marquee');
        const marquee = (window.m = new Marquee($marquee, {
            rate: -40,
            startOnScreen: true, // start on screen
        }));

        window.l = loop(
            marquee,
            [
                function () {
                    return 'UI/UX';
                },
                function () {
                    return 'WEB';
                },
                function () {
                    return '3D VISION';
                },
                function () {
                    return 'FRONT END';
                },
                function () {
                    return 'DESIGNER';
                },
                function () {
                    return 'FREELANCER';
                },
            ],
            () => {
                const $separator = document.createElement('div');
                $separator.innerHTML = '&nbsp•&nbsp';
                return $separator;
            },
        );
    },

}


</script>