<!--
 * @Author: zzw131 750865560@qq.com
 * @Date: 2024-09-06 13:50:47
 * @LastEditors: zzw131 750865560@qq.com
 * @LastEditTime: 2024-09-20 09:49:14
 * @FilePath: /rio/Users/zouzhiwei/Desktop/myvue2/myvue/src/views/3DIcon.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%Ah1
-->
<template>







    <img class="bg-gradient" src="../bootstrap/img/gradient2.png" alt="">

    <div class="bg-noise"></div>
    <div class="bgyanse"></div>
    <!-- 文字滚动 -->
    <div class="marquee-text js-marquee-info border-bottom" id="marquee"> </div>
    <!-- 首页按钮 -->
    <button @click="goToHome" class="btn btn-outline-dark tiaozhanbtn_Prev"><i class="bi bi-house-fill"></i></button>




    <div class="container">
        <p class="smtext"> 3d Icons Pack</p>
        <p class="bigtext"> 3d 图标库</p>
        <div style="margin: 120px 0 0 0;" class="row">
            <div class="col-12 col-md-6">
                <p class="biaoti1">Blender + Figma + PNG</p>
                <p class="text1"> 那些 3D图标适用于用户界面设计、网页设计 <br>
                    登录页面等等。
                </p>
                <p class="text1">
                    - 3D Blender 文件（完全可定制）
                    <br>
                    - 白色和粉红色颜色样式文件<br>
                    - 高分辨率图像 3000x3000 (PNG)
                </p>
                <button type="button" class="btn btn-outline-dark btn1">Open Figma <i
                        class="bi bi-folder-symlink"></i></button>




                <div class="btn-group btn1" role="group">
                    <button type="button" class="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Dropdown
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item btn btn-outline-dark" href="#">Download(Drive) <i
                                    class="bi bi-cloud-download"></i></a></li>
                        <li><a class="dropdown-item btn btn-outline-dark" href="#">百度网盘(0220) <i
                                    class="bi bi-cloud-download"></i></a></li>
                    </ul>
                </div>



            </div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
                <div class="yuanxing magnetic">
                    <p>3D<br>ICON</p>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center" style="  margin: 5vw 0 0 0;">

         
            <img style="border-radius: 30px;   pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;" class="img-fluid" src="../bootstrap/img/3Dheji.png" alt="">


       

        </div>





    </div>
</template>
<style scoped>
.tiaozhanbtn_Prev {
    border: none;
    height: 40px;
    width: 40px;
    text-align: center;
    background-color: #000000;
    color: #ffffff;
    border-radius: 10px;
    transition: all 1s ease;
    transform: scale(1.2);
    justify-content: center;
    align-items: center;
    /* 或者 50% */
    position: fixed;
    bottom: 130px;
    right: 40px;
}


@media only screen and (max-width: 768px) {

    /* 头图走马灯 */
    .marquee-text {
        font-size: 0.1vw;
        background-color: transparent;
        padding: 0;
        letter-spacing: 0px;
        color: #33333300;
        height: 1px;
        border-bottom: 0;
    }

    .yuanxing {
        color: #FFFFFF;
        width: clamp(160px, 20vw, 200px);
        height: clamp(160px, 20vw, 200px);
        background-color: rgb(0, 0, 0);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 40px 0 0 0;
   
    }
}




@media only screen and (min-width: 769px) {

    /* 走马灯 */
    .marquee-text {
        font-size: 1.8vw;
        background-color: transparent;
        font-weight: 400;
        padding: 20px;
        letter-spacing: 5px;
    }

    .yuanxing {
        color: #FFFFFF;
        width: clamp(160px, 20vw, 200px);
        height: clamp(160px, 20vw, 200px);
        background-color: rgb(0, 0, 0);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

    }

    .yuanxing:hover {
        background: #4B2DD0;
    }
}


.biaoti1 {
    font-size: clamp(24px, 5vw, 40px);
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    color: #333333;
    margin: 0;
}

.text1 {
    font-size: clamp(16px, 3vw, 20px);
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 200;
    color: #333333;
    margin: 0;
    padding: 20px 0 0 0;
    line-height: 1.6;
}

.btn1 {
    margin: 30px 30px 0 0;

}

.smtext {
    font-size: 20px;
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 300;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 5vw 0 0;
}

.bigtext {
    font-size: clamp(48px, 5vw, 100px);
    font-family: "PingFang", Arial, sans-serif;
    font-weight: 900;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 30px 0 0 0;
}

/* 背景 */
.bg-noise {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-image: url(../bootstrap/img/noise.gif);
    background-position: 0 0, 0 0;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    opacity: .05;
}

.bg-gradient {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100vh;
    background-image: url(../bootstrap/img/gradient2.jpg);
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;

}

.bg-gradient::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 15vh;
    background: linear-gradient(180deg, rgba(236, 239, 248, 0) 11.11%, #EDEFF8 100%);
}

.bgyanse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f7f8ff;
    z-index: -3;
    /* 确保在.bg-noise 下面 */
}
</style>

<script>
//滚动字幕
import { Marquee, loop, } from 'dynamic-marquee';
import { gsap } from 'gsap';
import { Power4 } from 'gsap';
export default {
    methods: {
        goToHome() {
            this.$router.push({ path: '/' });
        },
    },

    mounted() {

        //js动画 魔法

        var magnets = document.querySelectorAll('.magnetic');
        var strength = 50;

        magnets.forEach((magnet) => {
            magnet.addEventListener('mousemove', moveMagnet);
            magnet.addEventListener('mouseout', function (event) {
                gsap.to(event.currentTarget, 1, { x: 0, y: 0, ease: Power4.easeOut });
            });
        });

        function moveMagnet(event) {
            var magnetButton = event.currentTarget;
            var bounding = magnetButton.getBoundingClientRect();
            gsap.to(magnetButton, 1, {
                x: (((event.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * strength,
                y: (((event.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * strength,
                ease: Power4.easeOut,
            });
        }
        //头图
        const $marquee = document.getElementById('marquee');
        const marquee = (window.m = new Marquee($marquee, {
            rate: -40,
            startOnScreen: true, // start on screen
        }));

        window.l = loop(
            marquee,
            [
                function () {
                    return 'UI/UX';
                },
                function () {
                    return 'WEB';
                },
                function () {
                    return '3D VISION';
                },
                function () {
                    return 'FRONT END';
                },
                function () {
                    return 'DESIGNER';
                },
                function () {
                    return 'FREELANCER';
                },
            ],
            () => {
                const $separator = document.createElement('div');
                $separator.innerHTML = '&nbsp•&nbsp';
                return $separator;
            },
        );
    },

}


</script>