<template>

  <div>
    <!-- -->


    <!-- top按钮 -->
    <el-backtop :bottom="200">
      <div class="btntop"> ↑ </div>
    </el-backtop>
    <div class="preloader-wrapper" id="preloader">
      <div class="preloader-inner">
        <h1 class="typing-text preloader-text"></h1>
      </div>
    </div>
  </div>

  <router-view />
  <div class="shouji">
    <div class="navbottom row">
      <div class="col-3 navleft"> <img src="../src/bootstrap/img/LOGO.png" alt=""> </div>


      <div class="col-6 navright ">



        <div class="col-auto navrightbtnhei">
          <a @click="goToHome" style="margin-right: 6px;  cursor: pointer;" class="navbtn_home"><i
              class="bi bi-house-door"></i> 主页</a>
          <a @click="goToPortfolio" style="margin-right: 6px;  cursor: pointer;" class="navbtn_home">作品集 </a>
          <a @click="goToDocs" style="margin-right: 6px;  cursor: pointer;" class="navbtn_home">博客</a>
          <button style="border: 0;background-color: #00000000; margin: 0;padding: 0;" type="button"
            data-bs-toggle="modal" data-bs-target="#exampleModal">
            <div class="navbtn_home">联系我</div>
          </button>
        </div>
      </div>



      <!-- <div class="col-3" style="width: 60px;height: 60px;background-color: #000000;color:azure;display: flex;
flex-direction: column;
justify-content: center;text-align: center; 
border-radius: 6px;margin-left: 10px;
"> logo </div> -->

    </div>

    <div style="margin-top: 50px;" class="container ">
      <section>
        <div class="row ">
          <div class="col-md-6 col-12">
            <p class="text-start yejiaotext_1">Contact</p>

            <p class="yejiaotext_2 magnetic">13470030630@sina.cn</p>
            <div class="yejiaotext_3row">
              <span @click="dialogVisible = true" class="yejiaotext_3">Recomenmend Visit: </span>






              <button style="border: 0;background-color: #00000000;" type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModal">
                <span class="yejiaotext_3_wacht">&nbsp;微 信&nbsp;</span>
              </button>


              <div style="background-color: #00000040;" class="modal fade " id="exampleModal" tabindex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div style="background-color: #00000000; border: 0;text-align: center;" class="modal-content ">

                    <div class="modal-body ">
                      <img style="width: 80%;" src="../src/bootstrap/img/card.png" class="img-fluid" alt="...">

                    </div>
                    <span style="text-align: center;color: #ffffff;" type="button" data-bs-dismiss="modal">X</span>

                  </div>
                </div>
              </div>

              <span class="yejiaotext_3">and</span>

              <span @click="goToDocs" class="yejiaotext_3_boke">&nbsp;博 客&nbsp;
              </span>

            </div>

          </div>

          <div class="col-md-6 col-12 d-flex justify-content-center align-items-center">
            <img class="tuzi magnetic" src="../src/bootstrap/img/tuzi.png" alt="">
          </div>
        </div>
      </section>
      <footer>


        <div class="row yejiaofenge d-flex justify-content-between">
          <span class="banquantext col-auto">
            <a href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?spm=5176.28508143.J_9220772140.58.244e154aUPHCZf&amp;showType=p&amp;serial=91330106673959654P-SAIC_SHOW_10000091330106673959654P1710919400712&amp;signData=MEUCIQDEkCd8cK7%2Fyqe6BNMWvoMPtAnsgKa7FZetfPkjZMsvhAIgOX1G9YC6FKyndE7o7hL0KaBVn4f%20V%2Fiof3iAgpsV09o%3D"
              target="_blank" class="ali-report-link" data-spm-anchor-id="5176.28508143.J_9220772140.58"><img
                style="height: 40px;" src="//gw.alicdn.com/tfs/TB1GxwdSXXXXXa.aXXXXXXXXXXX-65-70.gif"
                class="ali-report-img" alt="" loading="lazy"
                data-spm-anchor-id="5176.28508143.J_9220772140.i0.244e154aUPHCZf"></a><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=5176.28508143.J_9220772140.59.244e154aUPHCZf"
              target="_blank" class="ali-report-link" data-spm-anchor-id="5176.28508143.J_9220772140.59"> <img
                style="height: 35px;" src="//img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png"
                class="ali-report-img" alt="浙公网安备 33010602009975号" loading="lazy"><span class="ali-report-link-text"
                data-spm-anchor-id="5176.28508143.J_9220772140.i1.244e154aUPHCZf">京ICP备
              </span></a><a href="https://beian.miit.gov.cn/?spm=5176.28508143.J_9220772140.60.244e154aUPHCZf"
              target="_blank" class="ali-report-link" data-spm-anchor-id="5176.28508143.J_9220772140.60"><span
                class="ali-report-link-text ali-report-link-text-unique"> 2024079239号-1</span></a>
          </span>



          <div class="col-auto">
            <span class="lianxifangshitext"> Social: </span>
            <span class="lianxifangshitext_btn" @click="goToDocs"> Blog</span>
            <a style="text-decoration: none;" href="https://dribbble.com/xiaowei00167"> <span
                class="lianxifangshitext_btn"> Dribbble</span></a>
            <a style="text-decoration: none;" href="https://www.zcool.com.cn/u/22637688"> <span
                class="lianxifangshitext_btn"> Zcool</span> </a>
          </div>
        </div>



      </footer>



    </div>
  </div>



</template>

<script>
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
// import { Marquee, loop, } from 'dynamic-marquee';

export default {
  name: 'App',

  data() {
    return {

    };
  },




  setup() {
    // 创建一个 ref 来存储文本元素引用
    const textElement = ref(null);
    // 创建一个数组来存储要循环显示的单词
    const words = ref(['加载中...', '页面渲染.', '马上好了！.']);
    // 创建变量来控制打字方向、当前单词索引和当前字母索引
    const direction = ref(0);
    const wordIndex = ref(0);
    const letterIndex = ref(0);
    // 创建一个定时器引用
    let wordTypeInterval;

    // 开始打字效果的方法
    const startTyping = () => {
      wordTypeInterval = setInterval(typeLetter, 50); // 减少字母出现时间间隔
    };

    // 逐个字母显示或隐藏的方法
    const typeLetter = () => {
      const word = words.value[wordIndex.value];
      if (direction.value === 0) {
        letterIndex.value++;
        if (letterIndex.value === word.length) {
          direction.value = 1;
          clearInterval(wordTypeInterval);
          setTimeout(startTyping, 800); // 减少单词停留时间
        }
      } else if (direction.value === 1) {
        letterIndex.value--;
        if (letterIndex.value === 0) {
          nextWord();
        }
      }
      const textToType = word.substring(0, letterIndex.value);
      textElement.value.textContent = textToType;
    };

    // 切换到下一个单词的方法
    const nextWord = () => {
      letterIndex.value = 0;
      direction.value = 0;
      wordIndex.value++;
      if (wordIndex.value === words.value.length) {
        wordIndex.value = 0;
      }
    };

    // 在组件挂载后启动打字效果和隐藏预加载器
    onMounted(() => {
      textElement.value = document.querySelector('.typing-text');
      startTyping();
      // 加载缓慢时强制隐藏预加载器
      setTimeout(() => {
        const preloader = document.getElementById('preloader');
        preloader.style.display = 'none';
      }, 2000);
    });


    const router = useRouter();
    const goToPortfolio = () => {
      router.push({ name: 'portfolio' });
    };

    const goToHome = () => {
      router.push({ name: 'home' });
    };

    return {
      goToPortfolio,
      goToHome,




      textElement,

    };
  },




  methods: {
    goToDocs() {
      // 假设部署在服务器上时，根目录相对路径依然有效
      window.location.href = '/docs/index.html';
    },
  },
};
</script>

<style scoped>

@media only screen and (max-width: 768px) {
  .shouji {
    display: none;
  }

  .navbottom {
    display: none;
  }

  .btntop {
    border: none;
    height: 40px;
    width: 40px;
    text-align: center;
    background-color: #000000;
    color: #ffffff;
    border-radius: 10px;
    transition: all 1s ease;
    transform: scale(1.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /* 页脚 */
  .lianxifangshitext {
    font-size: 3vw;
  }

  .lianxifangshitext_btn {
    margin: 0 2vw 0 2vw;
    font-size: 3vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .lianxifangshitext_btn::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .lianxifangshitext_btn:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }


  .banquantext {
    margin: 0;
    padding: 0;
    padding-left: 10px;
    font-size: 3vw;

  }

  .tuzi {
    width: 23vw;
    height: 100%;
    object-fit: contain;
    margin-bottom: 6vw;
  }

  .yejiaotext_1 {
    font-size: 3vw;
    text-transform: uppercase;
    margin-bottom: 10vw;
  }

  .yejiaotext_3row {
    margin-top: 3vw;
    margin-bottom: 10vw;
  }


  .yejiaotext_2 {
    font-size: 4vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 45%;

  }

  .yejiaotext_2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }



  .yejiaotext_3_wacht {
    font-size: 3.5vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .yejiaotext_3 {
    font-size: 3vw;
    margin-bottom: 10vw;
  }

  .yejiaotext_3_wacht::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_3_wacht:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }



  .yejiaotext_3_boke {
    font-size: 3.5vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .yejiaotext_3_boke::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_3_boke:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  /* 页脚结束 */

}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .btntop {
    border: none;
    height: 40px;
    width: 40px;
    text-align: center;
    background-color: #000000;
    color: #ffffff;
    border-radius: 10px;
    transition: all 1s ease;
    transform: scale(1.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* 页脚 */
  .lianxifangshitext {
    font-size: 1.2vw;
  }

  .lianxifangshitext_btn {
    margin: 0 2vw 0 2vw;
    font-size: 1.2vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .lianxifangshitext_btn::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .lianxifangshitext_btn:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }


  .banquantext {
    margin: 0;
    padding: 0;
    letter-spacing: 3px;
  }

  .tuzi {
    width: 23vw;
    height: 100%;
    object-fit: contain;
  }

  .yejiaotext_1 {
    font-size: 1.5vw;
    text-transform: uppercase;
    margin-bottom: 10vw;
  }

  .yejiaotext_3row {
    margin-top: 3vw;
    margin-bottom: 10vw;
  }


  .yejiaotext_2 {
    font-size: 3vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .yejiaotext_2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  .yejiaotext_3 {
    font-size: 1.1vw;
    margin-bottom: 10vw;
  }

  .yejiaotext_3_wacht {
    font-size: 1.2vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .yejiaotext_3_wacht::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_3_wacht:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }



  .yejiaotext_3_boke {
    font-size: 1.2vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .yejiaotext_3_boke::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_3_boke:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  /* 页脚结束 */




}

/* 电脑端 */
@media only screen and (min-width: 993px) {

  /* 底部导航按钮 */
  .navleft {
    width: 58px;
    height: 58px;
    background-color: #000000;
    color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    padding: 0;
    margin-right: 6px;

  }
  .navright {
    height: 58px;
    width: auto;
    background-color: #3E3E3E;
    color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    padding: 4px;

  }

  .navbtn_home {
    display: inline-block;
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.800);
    text-decoration: none;
    border: 1px solid #ffffff2e;
    border-radius: 5px;
    background-color: transparent;
    transition: all 0.3s ease;
    font-family: 'Noto Sans SC', sans-serif;
    font-weight: 300;


  }

  .navbtn_home:hover {

    border: 1px solid #ffffff;
  }

  .navbottom {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4D4D4D;
    border-radius: 10px;
    width: max-content;
    height: 70px;
    z-index: 9999;
    padding: 6px;
    transition: all 1s ease;
  }
/* 底部 */

  .lianxifangshitext_btn {
    cursor: pointer;

  }

  .btntop {
    border: none;
    height: 40px;
    width: 40px;
    text-align: center;
    background-color: #000000;
    color: #ffffff;
    border-radius: 10px;
    transition: all 1s ease;
    transform: scale(1.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /* 页脚 */
  .lianxifangshitext {
    font-size: 1.2vw;
  }

  .lianxifangshitext_btn {
    margin: 0 2vw 0 2vw;
    font-size: 1.2vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .lianxifangshitext_btn::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .lianxifangshitext_btn:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }


  .banquantext {
    margin: 0;
    padding: 0;
    letter-spacing: 3px;
  }

  .tuzi {
    width: 23vw;
    height: 100%;
    object-fit: contain;
  }

  .yejiaotext_1 {
    font-size: 1.5vw;
    text-transform: uppercase;
    margin-bottom: 10vw;
  }

  .yejiaotext_3row {
    margin-top: 3vw;
    margin-bottom: 10vw;
  }


  .yejiaotext_2 {
    font-size: 3vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .yejiaotext_2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_2:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  .yejiaotext_3 {
    font-size: 1.1vw;
    margin-bottom: 10vw;
  }

  .yejiaotext_3_wacht {
    font-size: 1.2vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .yejiaotext_3_wacht::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_3_wacht:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }



  .yejiaotext_3_boke {
    font-size: 1.2vw;
    position: relative;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid rgb(162, 162, 162);
    width: 80%;

  }

  .yejiaotext_3_boke::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: gray;
    transition: width 1s ease;
  }

  .yejiaotext_3_boke:hover::after {
    background-color: black;
    width: 100%;
    transition: width 1s ease;
  }

  /* 页脚结束 */

}



  /* 超大屏幕电脑端 */
@media only screen and (min-width: 2048px) {

/* 底部导航按钮 */

.navleft {
    width: 80px;
    height: 80px;
    background-color: #000000;
    color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    padding: 0;
    margin-right: 6px;

  }
  .navright {
    display: flex;
  align-items: center;
  justify-content: center;
    height: auto;
    width: auto;
    background-color: #3E3E3E;
    color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    padding: 6px;
  }


  .navbtn_home {
    display: inline-flex; /* 修改为 inline-flex */
    align-items: center; /* 添加这行代码 */
    color: rgba(255, 255, 255, 0.800);
    text-decoration: none;
    border: 1px solid #ffffff2e;
    border-radius: 4px;
    background-color: transparent;
    transition: all 0.3s ease;
    font-family: 'Noto Sans SC', sans-serif;
    font-weight: 300;
    height: 68px;
    text-align: center;
    font-size: 18px;
}

  .navbtn_home:hover {

    border: 1px solid #ffffff;
  }

  .navbottom {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4D4D4D;
    border-radius: 10px;
    width: max-content;
    height: 100px;
    z-index: 9999;
    padding: 10px;
    transition: all 1s ease;
  }

    /* 页脚 */
    .lianxifangshitext {
      font-size: 1vw;
    }

    .lianxifangshitext_btn {
      margin: 0 2vw 0 2vw;
      font-size: 1.2vw;
      position: relative;
      text-decoration: none;
      color: #000;
      border-bottom: 2px solid rgb(162, 162, 162);
      width: 80%;

    }

    .lianxifangshitext_btn::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: gray;
      transition: width 1s ease;
    }

    .lianxifangshitext_btn:hover::after {
      background-color: black;
      width: 100%;
      transition: width 1s ease;
    }


    .banquantext {
      padding-left: 10px;
      letter-spacing: 3px;
      font-size: 1vw;

    }

    .tuzi {
      width: 23vw;
      height: 100%;
      object-fit: contain;
    }

    .yejiaotext_1 {
      font-size: 1.5vw;
      text-transform: uppercase;
      margin-bottom: 10vw;
    }

    .yejiaotext_3row {
      margin-top: 3vw;
      margin-bottom: 10vw;
    }


    .yejiaotext_2 {
      font-size: 2vw;
      position: relative;
      text-decoration: none;
      color: #000;
      border-bottom: 2px solid rgb(162, 162, 162);
      width: 80%;

    }

    .yejiaotext_2::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: gray;
      transition: width 1s ease;
    }

    .yejiaotext_2:hover::after {
      background-color: black;
      width: 100%;
      transition: width 1s ease;
    }

    .yejiaotext_3 {
      font-size: 1.1vw;
      margin-bottom: 10vw;
    }

    .yejiaotext_3_wacht {
      font-size: 1.2vw;
      position: relative;
      text-decoration: none;
      color: #000;
      border-bottom: 2px solid rgb(162, 162, 162);
      width: 80%;

    }

    .yejiaotext_3_wacht::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: gray;
      transition: width 1s ease;
    }

    .yejiaotext_3_wacht:hover::after {
      background-color: black;
      width: 100%;
      transition: width 1s ease;
    }



    .yejiaotext_3_boke {
      font-size: 1.2vw;
      position: relative;
      text-decoration: none;
      color: #000;
      border-bottom: 2px solid rgb(162, 162, 162);
      width: 80%;

    }

    .yejiaotext_3_boke::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: gray;
      transition: width 1s ease;
    }

    .yejiaotext_3_boke:hover::after {
      background-color: black;
      width: 100%;
      transition: width 1s ease;
    }

    /* 页脚结束 */
  }




@media only screen and (min-width: 3072px) {


/* 底部导航按钮 */

.navleft {
   width: 120px;
   height:120px;
   background-color: #000000;
   color: azure;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   border-radius: 6px;
   padding: 0;
   margin-right: 6px;

 }
 .navright {
   display: flex;
 align-items: center;
 justify-content: center;
   height: auto;
   width: auto;
   background-color: #3E3E3E;
   color: azure;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   border-radius: 6px;
   padding: 10px;
 }


 .navbtn_home {
   display: inline-flex; /* 修改为 inline-flex */
   align-items: center; /* 添加这行代码 */
   color: rgba(255, 255, 255, 0.800);
   text-decoration: none;
   border: 1px solid #ffffff2e;
   border-radius: 4px;
   background-color: transparent;
   transition: all 0.3s ease;
   font-family: 'Noto Sans SC', sans-serif;
   font-weight: 300;
   height: 100px;
   text-align: center;
   font-size: 28px;
   padding-left: 20px;
   padding-right: 20px;
}

 .navbtn_home:hover {

   border: 1px solid #ffffff;
 }

 .navbottom {
   position: fixed;
   bottom: 30px;
   left: 50%;
   transform: translateX(-50%);
   background-color: #4D4D4D;
   border-radius: 10px;
   width: max-content;
   height: 140px;
   z-index: 9999;
   padding: 10px;
   transition: all 1s ease;
 }



 .lianxifangshitext_btn {
   cursor: pointer;

 }

 .container {
   max-width: 2048px;
   /* 或者其他你想要的宽度值 */
 }

 .btntop {
   border: none;
   height: 68px;
   width: 68px;
   text-align: center;
   background-color: #000000;
   color: #ffffff;
   border-radius: 10px;
   transition: all 1s ease;
   transform: scale(1.2);
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 32px;
 }
}


@media only screen and (min-width: 4096px) {

/* 底部导航按钮 */


.navleft {
   width: 120px;
   height:120px;
   background-color: #000000;
   color: azure;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   border-radius: 6px;
   padding: 0;
   margin-right: 6px;

 }
 .navright {
   display: flex;
 align-items: center;
 justify-content: center;
   height: auto;
   width: auto;
   background-color: #3E3E3E;
   color: azure;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   border-radius: 6px;
   padding: 10px;
 }


 .navbtn_home {
   display: inline-flex; /* 修改为 inline-flex */
   align-items: center; /* 添加这行代码 */
   color: rgba(255, 255, 255, 0.800);
   text-decoration: none;
   border: 1px solid #ffffff2e;
   border-radius: 4px;
   background-color: transparent;
   transition: all 0.3s ease;
   font-family: 'Noto Sans SC', sans-serif;
   font-weight: 300;
   height: 100px;
   text-align: center;
   font-size: 28px;
   padding-left: 20px;
   padding-right: 20px;
}

 .navbtn_home:hover {

   border: 1px solid #ffffff;
 }

 .navbottom {
   position: fixed;
   bottom: 30px;
   left: 50%;
   transform: translateX(-50%);
   background-color: #4D4D4D;
   border-radius: 10px;
   width: max-content;
   height: 140px;
   z-index: 9999;
   padding: 10px;
   transition: all 1s ease;
 }



 .lianxifangshitext_btn {
   cursor: pointer;

 }

 .container {
   max-width: 2048px;
   /* 或者其他你想要的宽度值 */
 }

 .btntop {
   border: none;
   height: 68px;
   width: 68px;
   text-align: center;
   background-color: #000000;
   color: #ffffff;
   border-radius: 10px;
   transition: all 1s ease;
   transform: scale(1.2);
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 32px;
 }
}


@media only screen and (min-width: 4096px) {

/* 底部导航按钮 */

.navleft {
    width: 160px;
    height: 160px;
    background-color: #000000;
    color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    padding: 0;
    margin-right: 6px;

  }
  .navright {
    display: flex;
  align-items: center;
  justify-content: center;
    height: auto;
    width: auto;
    background-color: #3E3E3E;
    color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    padding: 6px;
  }


  .navbtn_home {
    display: inline-flex; /* 修改为 inline-flex */
    align-items: center; /* 添加这行代码 */
    color: rgba(255, 255, 255, 0.800);
    text-decoration: none;
    border: 1px solid #ffffff2e;
    border-radius: 4px;
    background-color: transparent;
    transition: all 0.3s ease;
    font-family: 'Noto Sans SC', sans-serif;
    font-weight: 300;
    height: 140px;
    text-align: center;
    font-size:32px;
    padding-left: 40px;
    padding-right: 40px;
}

  .navbtn_home:hover {

    border: 1px solid #ffffff;
  }

  .navbottom {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4D4D4D;
    border-radius: 10px;
    width: max-content;
    height: 180px;
    z-index: 9999;
    padding: 10px;
    transition: all 1s ease;
  }

  .lianxifangshitext_btn {
    cursor: pointer;

  }

  .container {
    max-width: 3072px;
    /* 或者其他你想要的宽度值 */
  }

  .btntop {
    border: none;
    height: 100px;
    width: 100px;
    text-align: center;
    background-color: #000000;
    color: #ffffff;
    border-radius: 10px;
    transition: all 1s ease;
    transform: scale(1.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }

}

/* 页脚 */
.lianxifangshitext {
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 900;
  color: #333333;
}

.lianxifangshitext_btn {
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 300;
  color: #333333;
}

.yejiaofenge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-top: clamp(24px, 3vw, 48px);
  font-size: 14px;
  font-weight: 400;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #222), color-stop(10%, rgba(255, 255, 255, 0)));
  background-image: -o-linear-gradient(left, #222 0 10%, rgba(255, 255, 255, 0) 10%);
  background-image: linear-gradient(to right, #222 0 10%, rgba(255, 255, 255, 0) 10%);
  background-position: top;
  background-size: 8px 1px;
  background-repeat: repeat-x
}

.yejiaotext_1 {
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 200;
  color: #333333;
}

.yejiaotext_3 {
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 300;
  color: #666666;
}

.yejiaotext_2 {
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 900;
  color: #333333;
}



.yejiaotext_3_wacht {
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 900;
  color: #333333;

}




.yejiaotext_3_boke {
  font-family: "PingFang", Arial, sans-serif;
  font-weight: 900;
  color: #333333;

}

/* 页脚结束 */








body {
  width: 100%;
}

.btntop:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.305);
  /* 鼠标悬停时添加投影 */
  transform: scale(1.3);
  /* 鼠标悬停时放大按钮 */
  display: flex;
  justify-content: center;
  align-items: center;
}

/*------------------------------------------------------------------
 # preloader style
-------------------------------------------------------------------*/
.preloader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
  text-align: center;
  overflow: hidden;
  background-color: #edeff8;
  transition: 1s;
}

.preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transition: 0.7s ease;
  transform: translate(-50%, -50%);
}

.preloader-wrapper.loaded {
  height: 0;
}

.preloader-wrapper.loaded img {
  opacity: 0;
}

.preloader-text {
  font-size: 60px;
  font-size: clamp(60px, 36.323943662px + 2.5352112676vw, 96px);
  line-height: normal;
  color: #222;
}

.typing-text::after {
  content: '|';
  animation: blink ease 0.7s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
</style>